import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueLazyLoad from 'vue-lazyload'
import VueCookies from 'vue-cookies'


// import style (>= Swiper 6.x)
import '../node_modules/swiper/swiper-bundle.css'
import './plugins/axios'


import '@/utils/global.css'

Vue.config.productionTip = false

Vue.use(VueAwesomeSwiper)
Vue.use(VueLazyLoad)
Vue.use(VueCookies)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
