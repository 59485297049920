<template>
    <div id="advantage">
        <div class="advantage-container flex-row">
            <div class="advantage-left flex-colum position-rel">
                <div class="row-pane flex-row">
                    <div class="advantage-comm-el" style="background: linear-gradient(-45deg, #CAD4FF 0%, rgba(255,255,255,0) 40%); margin-right: 0.25rem">
                        <div class="comm-el-nav position-rel" style="color: #666">
                            <div class="small-cric round flex-colum flex-center bold left-top" style="left: 0.31rem; bottom: 0.31rem; background: #C9D1FE;">
                                <span class="flex-colum flex-center">先进条件</span>
                            </div>
                            <div class="small-cric round flex-colum flex-center bold left-top" style="left: 2.56rem; bottom: 5.69rem; background: #C9D1FE;">
                                <span class="flex-colum flex-center">学术环境</span>
                            </div>
                            <div class="small-cric round flex-colum flex-center bold left-top" style="left: 7rem; bottom: 10.06rem; background: #C9D1FE;">
                                <span class="flex-colum flex-center">研究专才</span>
                            </div>
                            <div class="small-cric round flex-colum flex-center bold left-top" style="left: 12.63rem; bottom: 12.19rem; background: #C9D1FE;">
                                <span class="flex-colum flex-center">创新思维</span>
                            </div>
                            <div class="big-cric round flex-colum flex-align-center bold big-left-top" @mouseenter="t('left-top')" style="right: 0.69rem; bottom: 0.31rem; background: #C9D1FE; transform-origin: 100% 100%;">
                                <span id="span-left-top" class="flex-colum flex-center" style="margin-top: 2.63rem;">科研培养</span>
                            </div>
                        </div>
                    </div>

                    <div class="advantage-comm-el" style="background: linear-gradient(45deg, #CAD4FF 0%, rgba(255,255,255,0) 40%)">
                        <div class="comm-el-nav position-rel" style="color: #fff">
                            <div class="small-cric round flex-colum flex-center bold right-top" style="right: 0.31rem; bottom: 0.31rem; background: #8071F6;">
                                <span class="flex-colum flex-center">企业选调</span>
                            </div>
                            <div class="small-cric round flex-colum flex-center bold right-top" style="right: 2.56rem; bottom: 5.69rem; background: #8071F6;">
                                <span class="flex-colum flex-center">高校就职</span>
                            </div>
                            <div class="small-cric round flex-colum flex-center bold right-top" style="right: 7rem; bottom: 10.06rem; background: #8071F6;">
                                <span class="flex-colum flex-center">落户移民</span>
                            </div>
                            <div class="small-cric round flex-colum flex-center bold right-top" style="right: 12.63rem; bottom: 12.19rem; background: #8071F6;">
                                <span class="flex-colum flex-center">人才引进</span>
                            </div>
                            <div class="big-cric round flex-colum flex-align-center bold big-right-top" @mouseenter.stop="t('right-top')" @click.stop="t('right-top')" style="left: 0.69rem; bottom: 0.31rem; background: #8071F6; transform-origin: 100% 100%;">
                                <span id="span-right-top" class="flex-colum flex-center" style="margin-top: 2.63rem;">职业发展</span>
                            </div>
                        </div>
                    </div>
                </div>   

                <div class="row-pane flex-row" style="margin-top: 0.25rem">
                    <div class="advantage-comm-el" style="background: linear-gradient(218deg, #CAD4FF 0%, rgba(255,255,255,0) 40%);margin-right: 0.25rem">
                        <div class="comm-el-nav position-rel" style="color: #fff">
                            <div class="small-cric round flex-colum flex-center bold bottom-left" style="left: 0.31rem; top: 0.31rem; background: #807CB8;">
                                <span class="flex-colum flex-center">多元文化</span>
                            </div>
                            <div class="small-cric round flex-colum flex-center bold bottom-left" style="left: 2.56rem; top: 5.69rem; background: #807CB8;">
                                <span class="flex-colum flex-center">开拓视野</span>
                            </div>
                            <div class="small-cric round flex-colum flex-center bold bottom-left" style="left: 7rem; top: 10.06rem; background: #807CB8;">
                                <span class="flex-colum flex-center">带薪读博</span>
                            </div>
                            <div class="small-cric round flex-colum flex-center bold bottom-left" style="left: 12.63rem; top: 12.19rem; background: #807CB8;">
                                <span class="flex-colum flex-center">人脉拓展</span>
                            </div>
                            <div class="big-cric round flex-colum flex-align-center bold big-bottom-left" @mouseenter.stop="t('bottom-left')" style="right: 0.69rem; top: 0.31rem; background: #807CB8; transform-origin: 100% 100%;">
                                <span id="span-bottom-left" class="flex-colum flex-center" style="margin-top: 4.69rem;">附加价值</span>
                            </div>
                        </div>
                    </div>

                    <div class="advantage-comm-el" style="background: linear-gradient(145deg, #CAD4FF 0%, rgba(255,255,255,0) 40%)">
                        <div class="comm-el-nav position-rel" style="color: #666">
                            <div class="small-cric round flex-colum flex-center bold bottom-right" style="right: 0.31rem; top: 0.31rem; background: #CAD4FF;">
                                <span class="flex-colum flex-center">学历加成</span>
                            </div>
                            <div class="small-cric round flex-colum flex-center bold bottom-right" style="right: 2.56rem; top: 5.69rem; background: #CAD4FF;">
                                <span class="flex-colum flex-center">深耕领域</span>
                            </div>
                            <div class="small-cric round flex-colum flex-center bold bottom-right" style="right: 7rem; top: 10.06rem; background: #CAD4FF;">
                                <span class="flex-colum flex-center">引领前沿</span>
                            </div>
                            <div class="small-cric round flex-colum flex-center bold bottom-right" style="right: 12.63rem; top: 12.19rem; background: #CAD4FF;">
                                <span class="flex-colum flex-center">独立研究</span>
                            </div>
                            <div class="big-cric round flex-colum flex-align-center bold big-bottom-right" @mouseenter.stop="t('bottom-right')" style="left: 0.69rem; top: 0.31rem; background: #CAD4FF; transform-origin: 100% 100%;">
                                <span id="span-bottom-right" class="flex-colum flex-center" style="margin-top: 4.69rem;">自我提升</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="center-pane position-abs">
                    <div class="center-cric round flex-colum flex-center">
                        <span>优势</span>
                    </div>
                </div>
            </div>
            <div class="advantage-right">
                <div :class="selected == 'left-top' ? 'advantage-right-container flex-colum selected-container' : 'advantage-right-container flex-colum'" style="margin-top: 4rem">
                    <div class="right-row-pane">
                        <div class="title flex-colum flex-center bold" style="background: #C9D1FE; color: #666;">先进条件</div>
                        <p>实验器材先进完备，科研经费充足，支持博士候选人全方位发展。</p>
                    </div>
                    <div class="right-row-pane">
                        <div class="title flex-colum flex-center bold" style="background: #C9D1FE; color: #666;">学术环境</div>
                        <p>科研氛围开放自由，支持以兴趣为导向的科学研究，沉浸式学习。</p>
                    </div>
                    <div class="right-row-pane">
                        <div class="title flex-colum flex-center bold" style="background: #C9D1FE; color: #666;">研究专才</div>
                        <p>针对各学科及交叉领域问题进行精细化研究，成就全球先锋人才。</p>
                    </div>
                    <div class="right-row-pane">
                        <div class="title flex-colum flex-center bold" style="background: #C9D1FE; color: #666;">创新思维</div>
                        <p>通过博士体系化训练培养创新开放与理性逻辑相结合的思考方式。</p>
                    </div>
                </div>
                <div :class="selected == 'right-top' ? 'advantage-right-container flex-colum selected-container' : 'advantage-right-container flex-colum'" style="margin-top: 4rem">
                    <div class="right-row-pane">
                        <div class="title flex-colum flex-center bold" style="background: #8071F6; color: #fff;">人才引进</div>
                        <p>政府重点引进对象，优先享受各级财政优惠补贴、人才培养政策。</p>
                    </div>
                    <div class="right-row-pane">
                        <div class="title flex-colum flex-center bold" style="background: #8071F6; color: #fff;">落户移民</div>
                        <p>国内一线城市轻松落户，海外高薪高待遇吸纳尖端领域人才移民。</p>
                    </div>
                    <div class="right-row-pane">
                        <div class="title flex-colum flex-center bold" style="background: #8071F6; color: #fff;">高校就职</div>
                        <p>各大高校教职必备条件，工作自由稳定假期长，子女教育有优势。</p>
                    </div>
                    <div class="right-row-pane">
                        <div class="title flex-colum flex-center bold" style="background: #8071F6; color: #fff;">企业选调</div>
                        <p>事业编制仕途晋升起点高，技术人才上升空间大，单位重点培养。</p>
                    </div>
                </div>
                <div :class="selected == 'bottom-left' ? 'advantage-right-container flex-colum selected-container' : 'advantage-right-container flex-colum'" style="margin-top: 4rem">
                    <div class="right-row-pane">
                        <div class="title flex-colum flex-center bold" style="background: #807CB8; color: #fff;">多元文化</div>
                        <p>感受文化差异之美，从不同角度观察审视学研文脉发展意义所在。</p>
                    </div>
                    <div class="right-row-pane">
                        <div class="title flex-colum flex-center bold" style="background: #807CB8; color: #fff;">开拓视野</div>
                        <p>拓宽眼界感受先端科学，扩展知识领域，以自身实践行至更高处。</p>
                    </div>
                    <div class="right-row-pane">
                        <div class="title flex-colum flex-center bold" style="background: #807CB8; color: #fff;">带薪读博</div>
                        <p>丰厚国际学业奖金或薪资，让你专注学术研究，生活无后顾之忧。</p>
                    </div>
                    <div class="right-row-pane">
                        <div class="title flex-colum flex-center bold" style="background: #807CB8; color: #fff;">人脉拓展</div>
                        <p>参与先端学术会议，结交专业领域大牛，建立国际学术人脉网络。</p>
                    </div>
                </div>
                <div :class="selected == 'bottom-right' ? 'advantage-right-container flex-colum selected-container' : 'advantage-right-container flex-colum'" style="margin-top: 4rem">
                    <div class="right-row-pane">
                        <div class="title flex-colum flex-center bold" style="background: #CAD4FF; color: #666;">学历加成</div>
                        <p>千分之一顶尖学历人才，海外名校学历背书，专业范畴认证学者。</p>
                    </div>
                    <div class="right-row-pane">
                        <div class="title flex-colum flex-center bold" style="background: #CAD4FF; color: #666;">深耕领域</div>
                        <p>立先开拓前沿知识领域，垂直方向拓宽技术栈道，破解领域迷思。</p>
                    </div>
                    <div class="right-row-pane">
                        <div class="title flex-colum flex-center bold" style="background: #CAD4FF; color: #666;">引领前沿</div>
                        <p>把握行业核心技术，引领前沿学术潮流，践行先人求知探索理念。</p>
                    </div>
                    <div class="right-row-pane">
                        <div class="title flex-colum flex-center bold" style="background: #CAD4FF; color: #666;">独立研究</div>
                        <p>锻炼从兴趣出发，发掘、筛选、拟定、独立解决问题的研究能力。</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import anime from 'animejs/lib/anime.es.js';
export default {
    data(){
        return{
            // animeDirection: true,
            animeDirectionSetp: {
                'top-left': false,
                'top-right': false,
                'bottom-left': false,
                'bottom-right': false
            },
            selected: ''
        }
    },
    mounted(){
        let that = this;
        let animeFlag = true;
        let fun = this.throttle(function(){
            if(animeFlag){
                if(document.querySelector('#advantage').getBoundingClientRect().top <= 400){
                animeFlag = false;
                    let mountedAnime = anime({
                        targets: '.advantage-left',
                        rotate: 360,
                        duration: 2000,
                                easing: 'easeInOutBack',
                        complete: function(anim) {
                            anime({
                                targets: '#advantage span',
                                opacity: 1,
                                duration: 800,
                                easing: 'easeInOutCubic',
                                complete: function(anim) {
                                    that.t('right-top');
                                    window.removeEventListener('scroll', fun);
                                    animeFlag = true;
                                }
                            },0)
                        }
                    });
                    return;
                }
            }
            
        },100 );
        window.addEventListener('scroll', fun);

    },
    methods:{
        throttle(func, wait, options) {
                    // 上下文，函数参数，函数返回值
            var context, args, result;
            // 延时器
            var timeout = null;
            // 上一次执行的func的时间点
            var previous = 0;
            if (!options) options = {};
            // 延时执行函数
            var later = function () {
            // 如果及时调用被关闭，则设置previous为0
            previous = options.leading === false ? 0 : new Date();
            timeout = null;
            result = func.apply(context, args);
            if (!timeout) context = args = null;
            };
            /** 以上变量以及函数都是通过闭包的方式访问的 **/
            return function () {
            var now = new Date();
            if (!previous && options.leading === false) previous = now;
            // remaining容易理解，表示还剩多少时间可以再次执行func
            var remaining = wait - (now - previous);
            // 保存上下文
            context = this;
            // 获取函数参数
            args = arguments;
            // 及时模式
            // remaining小于等于0是跳出wait的限制，可以执行了
            // remaining大于wait的情况，只有在客户机修改了系统时间的时候才会出现
            // 这两种情况都可以立刻对func做调用
            if (remaining <= 0 || remaining > wait) {
                // 清除定时器
                if (timeout) {
                clearTimeout(timeout);
                timeout = null;
                }
                previous = now;
                result = func.apply(context, args);
                if (!timeout) context = args = null;
            } else if (!timeout && options.trailing !== false) { // 延时模式
                timeout = setTimeout(later, remaining);
            }
            return result;
            };
        },
        t(selectorName){
                if(this.selected == selectorName){
                    return;
                }
                let scaleAnime = anime.timeline({
                    targets: '.big-'+selectorName,
                    width: '13rem',
                    height: '13rem',
                    fontSize: '2.38rem',
                    duration: 800,
                });
                scaleAnime.add({
                    targets: '.'+selectorName,
                    scale: 0,
                },0).add({
                    targets: '#span-'+selectorName,
                    marginTop: function(){
                        if(selectorName == 'bottom-left' || selectorName == 'bottom-right'){
                            return '6.87rem';
                        }else{
                            return '3.56rem';
                        }
                    },
                    complete: ()=>{}
                },0);
                this.selected = selectorName;
                // console.log(this.selected)
                this.animeDirectionSetp[selectorName] = true;
                for(let i in this.animeDirectionSetp){
                    if(this.animeDirectionSetp[i] && i != selectorName){
                        let scaleAnime = anime.timeline({
                            targets: '.big-'+i,
                            width: '9rem',
                            height: '9.06rem',
                            fontSize: '1.5rem',
                            // easing: 'linear',
                            duration: 700,
                        });
                        scaleAnime.add({
                            targets: '.'+i,
                            scale: 1,
                        },0).add({
                            targets: '#span-'+i,
                            marginTop: function(){
                                            if(i == 'bottom-left' || i == 'bottom-right'){
                                                return '4.69rem';
                                            }else{
                                                return '2.63rem';
                                            }
                                        },
                        },0);
                        // return;
                    }
                }
               
        }
    }
}
</script>
<style lang="scss" scoped>
    .selected-container{
        transform: translateX(0) !important;
        opacity: 1 !important;
    }
    .row-pane{
        width: 35.95rem;
        height: 18.1rem;
    }
    #advantage{
        margin-top: 3rem;
    span{
        opacity: 0;
    }
        .advantage-container{

            .advantage-left{
                width: 35.5rem;
                height: 36.5rem;
                .advantage-comm-el{
                    .comm-el-nav{
                        width: 17.58rem;
                        height: 18.1rem;
                        .small-cric{
                            // color: #666;
                            font-size: 1rem;
                            width: 4.75rem;
                            height: 4.75rem;
                            line-height: 1.25rem;
                            position: absolute;
                            span{
                                text-align: center;
                                width: 2.88rem;
                                height: 3.31rem;
                                font-size: 1rem;
                                // color: #666666;
                                line-height: 1.25rem;

                            }
                        }
                        .big-cric{
                            width: 9rem;
                            height: 9.06rem;
                            position: absolute;
                            cursor: pointer;
                            user-select:none;
                            // color: #666666;
                            font-size: 1.5rem;
                            span{
                                line-height: 2.06rem;
                                
                            }
                        }
                    }
                }
                .center-pane{
                    left: 12.6rem;
                    top: 13.25rem;
                    .center-cric{
                        width: 10.44rem;
                        height: 10.38rem;
                        background-image: radial-gradient(#8071F6 0%, #F4F0FE 100%);
                        // box-shadow: 0rem 0.13rem 1.38rem 0rem rgba(158, 85, 157, 0.48);
                        box-shadow: 0rem 0.13rem 1.38rem 0rem #8080DC;
                        color: #fff;
                        font-size: 2rem;
                            user-select:none;
                    }
                }
            }

            .advantage-right{
                margin-left: 4.25rem;
                .advantage-right-container{
                    // padding-top: rem;
                    position: absolute;
                    display: inline-block;
                    transform: translateX(4rem);
                    opacity: 0;
                    transition: all .3s;
                    .right-row-pane{
                        margin-top: 2.38rem;
                        width: 34.31rem;
                        height: 4.88rem;
                        .title{
                            width: 7.13rem;
                            height: 2.88rem;
                            border-radius: 0.2rem 0.8rem 0.2rem 0.8rem;
                            color: #fff;
                            font-size: 1.25rem;
                        }
                        p{
                            font-size: 1rem;
                            line-height: 1.38rem;
                            margin-top: 0.63rem;
                        }
                    }
                }
            }
        }    
    }
</style>