<template>
    <div id="system-pane">
        <div class="system-top">
            <my-subtitle text="热门留学地读博体系，来看看有没有你心仪的" index="-sub2"/>
                <div id="dbtx"></div>
                <div class="county-selector flex-colum flex-jusity-center position-rel">
                <div class="county-selector-container flex-row position-abs">
                    <div class="dot-container flex-colum flex-align-start flex-jusity-center">
                        <county-dot style="margin-left: -2.68rem; margin-top: 2.16rem" county="yg" :selected="selectedCounty == 'yg'" @checked="changeCounty('yg')"/>
                    </div>
                    <div class="dot-container">
                        <county-dot style="margin-top: 0.8rem; margin-left: -0.7rem" county="oz" :selected="selectedCounty == 'oz'" @checked="changeCounty('oz')"/>
                    </div>
                    <div class="dot-container flex-row flex-align-end">
                        <county-dot style="margin-bottom: 6.6rem; margin-left: 0.6rem" county="am" :selected="selectedCounty == 'am'" @checked="changeCounty('am')"/>
                    </div>
                    <div class="dot-container">
                        <county-dot style="margin-top: -2.7rem; margin-left: 1.7rem" county="bm" :selected="selectedCounty == 'bm'" @checked="changeCounty('bm')"/>
                    </div>
                    <div class="dot-container flex-colum flex-jusity-center">
                        <county-dot style="margin-bottom: 2.9rem;margin-left: 1.4rem" county="dyz" :selected="selectedCounty == 'dyz'" @checked="changeCounty('dyz')"/>
                    </div>
                    <div class="dot-container flex-colum flex-align-center">
                        <county-dot style="margin-top: 3.2rem; margin-left: 1.8rem" county="dny" :selected="selectedCounty == 'dny'" @checked="changeCounty('dny')"/>
                    </div>
                </div>
                <my-image style="z-index: 0" src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/county-icon/路径 2.png"/>
            </div>
        </div>
        <div class="system-bottom flex-colum flex-align-center">
            <div class="top-county-show flex-row flex-jusity-space-between flex-align-center" style="transform: scale(0.85);">
                <div class="county-name">
                    <span >{{countyNames[imgFlag]}}</span> 
                </div>
                <div class="county-brief flex-colum flex-jusity-center">
                    <span>{{countyBriefs[imgFlag]}}</span>
                </div>
                <div class="county-img flex-colum flex-center btn-action">
                    <my-image :lazyload="false" :src="`https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/system-county-${imgFlag}.png`"></my-image>
                </div>
            </div>
            <div class="bottom-county-show flex-row flex-jusity-center">
                <div class="bottom-nav flex-colum flex-align-center" >
                    <div class="nav-top bold" style="margin-right: 2rem">{{Object.keys(countySystem[imgFlag])[0]}}</div>
                    <div class="nav-bottom flex-colum flex-align-center" style="margin-right: 2rem">
                        <div :key="index" v-for="(item, index) in countySystem[imgFlag][Object.keys(countySystem[imgFlag])[0]]" class="bottom-row">{{item}}</div>
                        <!-- <div class="bottom-row">{{ite}}</div> -->
                    </div>
                </div>
                <div class="bottom-nav flex-colum flex-align-center">
                    <div class="nav-top bold" style="margin-left: 0.8rem">{{Object.keys(countySystem[imgFlag])[1]}}</div>
                    <div class="nav-bottom flex-colum flex-align-center" style="margin-left: 0.8rem">
                        <div :key="index" v-for="(item, index) in countySystem[imgFlag][Object.keys(countySystem[imgFlag])[1]]" class="bottom-row">{{item}}</div>
                    </div>
                </div>
                <div class="bottom-nav flex-colum flex-align-center">
                    <div class="nav-top bold">{{Object.keys(countySystem[imgFlag])[2]}}</div>
                    <div class="nav-bottom flex-colum flex-align-center">
                        <div :key="index" v-for="(item, index) in countySystem[imgFlag][Object.keys(countySystem[imgFlag])[2]]" class="bottom-row">{{item}}</div>
                    </div>
                </div>
                <div class="bottom-nav flex-colum flex-align-center">
                    <div class="nav-top bold" style="margin-left: 1.4rem">{{Object.keys(countySystem[imgFlag])[3]}}</div>
                    <div class="nav-bottom flex-colum flex-align-center" style="margin-left: 1.4rem">
                        <div :key="index" v-for="(item, index) in countySystem[imgFlag][Object.keys(countySystem[imgFlag])[3]]" class="bottom-row">{{item}}</div>
                    </div>
                </div>
                <div class="bottom-nav flex-colum flex-align-center">
                    <div class="nav-top bold" style="margin-left: 3.8rem">{{Object.keys(countySystem[imgFlag])[4]}}</div>
                    <div class="nav-bottom flex-colum flex-align-center" style="margin-left: 3.8rem">
                        <div :key="index" v-for="(item, index) in countySystem[imgFlag][Object.keys(countySystem[imgFlag])[4]]" class="bottom-row">{{item}}</div>
                    </div>
                </div>
                <div class="center-img position-abs">
                    <my-image src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/编组 49.png"/>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
import MyImage from '@components/MyImage';
import MySubtitle from '@components/MySubtitle';
import CountyDot from '@components/comm/CountyDot';

import anime from 'animejs/lib/anime.es.js';
export default {
    data(){
        return{
            selectedCounty: 'yg',
            imgFlag: 'yg',
            countyNames: {
                yg:'英国',
                oz:'欧洲',
                am:'中国香港\r中国澳门',
                bm:'北美',
                dyz:'大洋洲',
                dny:'东南亚',
            },
            countyBriefs: {
                yg: '英国有着享誉全球的高质量教育水准，博士学制较短，毕业无严格的论文发表数量要求，作为最大留学产业国，英国对华学生政策友好，开放全球引才，高端人才留英生活工作更容易。',
                oz: '欧洲作为一个高等教育院校林立，经济水平发达，自然环境优美的读博优选大区，一直被许多学子所青睐，欧洲名校教育质量优异，院校为学子提供了国际化的科研平台和机会。',
                am: '中国香港&中国澳门博士申请近年来尤为受广大学子喜爱，中国香港作为世界第三大金融中心，不仅是贸易、创新和文化交流汇集之处，还拥有着全球排名靠前的高等教育学府； 中国澳门读博，既能享受到国际化的教学模式，拥有境外求学经历，又为同学们提供了”在离家不远的地方做世界级科研“的机会。',
                bm: '美国以世界顶尖的学术科研水平，持续吸引全球学子前往深造，高额的奖学金以及科研资金，支撑科研人才学习、生活以及开展高精尖领域研究，学历认可度居世界前列；加拿大高等教育以严谨著称，它融和英国教育的一丝不苟和美国教育的灵活于一体，教育水平居世界前列。',
                dyz: '澳大利亚同意作为留学大国，世界一流的教学质量是其吸引留学生的根本，澳洲八大院校在排名皆数世界前列，充分显示了澳洲教育的学术品质。新西兰传承英国教育体制的精华，所发学历受到国际教育学术机构承认，新西兰读博学费相较于其他英联邦国家更为友好，且博士申请条件相对宽松。',
                dny: '东南亚热门博士留学国家包括新加坡&马来西亚，新加坡作为双语国家，既有同世界接轨的教育体系，又有适合中国学生的语言环境，成为许多中国学生留学的理想之地，且新加坡博士学制短，可申请本科直博；马来西亚留学环境好：经济发达，政治稳定，消费水平低，属英语系国家，但华语也较普遍，语言环境有助于学生迅速适应学习生活和增强英语沟通能力。'
            },
            countySystem:{
                yg: {
                    '学制': {zd: '导师制', time: '3-5年'},
                    '申请材料': ['学术简历（CV）','个人陈述（PS）','推荐信（RL）','研究计划（RP）','套磁信'],
                    '科教传媒': ['90次诺贝尔奖','大众传媒极具影响力'],
                    '金融领域': ['伦敦货币市场的交易量','超过纽约和东京的总和'],
                    '文化氛围': ['久负盛名的英国时装','历史悠久的艺术遗产']
                },
                oz: {
                    '学制': {zd: '多为岗位制', time: '3-4年'},
                    '申请材料': ['学术简历（CV）','个人陈述（PS）','推荐信（RL）','研究计划（RP）','动机信','套磁信'],
                    '学术底蕴': ['高等教育的发起地','高校历史底蕴丰厚','国际学术会议的首选'],
                    '旅游胜地': ['周边国家','地区旅游'],
                    '民族文化': ['不同的民族文化','拓宽眼界','结识人脉']
                },
                am: {
                    '学制': {zd: '导师制', time: '3-5年'},
                    '申请材料': ['学术简历（CV）','个人陈述（PS）','推荐信（RL）','研究计划（RP）','套磁信'],
                    '教学模式': ['教学国际化','高校研究模式','与国际接轨'],
                    '奖学金': ['政策公平','获得几率较高'],
                    '地理位置': ['离家近','适合毕业后在国内发展']
                },
                bm: {
                    '学制': {zd: '委员会制', time: '3-8年'},
                    '申请材料': ['学术简历','标化成绩','（GMAT、GRT、LSAT...）','论文或出版作品','推荐信','研究计划','...'],
                    '教学模式': ['注重学以致用','培养实用精神','培养动手能力'],
                    '就业环境': ['稳定发达的大经济体','就业机会较多','薪酬与付出的比例相对优渥'],
                    '学术文化': ['不同的民族文化','拓宽眼界','结识人脉']
                },
                dyz: {
                    '学制': {zd: '委员会制', time: '3-5年'},
                    '申请材料': ['学术简历（CV）','个人陈述（PS）','推荐信（RL）','研究计划（RP）','套磁信'],
                    '学术氛围': ['沿袭英美高等教育建设风气','宽松自由的学术氛围','规范的博士生培养体系'],
                    '科研资源': ['师生相互尊重','师生关系平等','贴近世界学术前沿','丰富的科研资源'],
                    '地理环境': ['优越的生活环境','秀丽的自然风光','适合长期生活发展']
                },
                dny: {
                    '学制': {zd: '委员会制', time: '3-5年'},
                    '申请材料': ['学术简历（CV）','推荐信（RL）','研究计划（RP）'],
                    '奖学金': ['留学费用相对低廉','奖学金多'],
                    '生活环境': ['生活习惯同中国相近','生活环境轻松熟悉','减轻课业压力'],
                    '风土人情': ['当地华人占比多','风土人情同中国相近']
                },
            }
        }
    },
    components:{
        MyImage,
        MySubtitle,
        CountyDot
    },
    methods:{
        changeCounty(county){
            this.selectedCounty = county;
            if(this.imgFlag != this.selectedCounty){
                this.changeAnime();
            }
        },
        changeAnime(){
            let changeAction = anime({
                // targets: ['.county-name', '.county-img', '.county-brief'],
                targets: '.top-county-show',
                scale: 0.6,
                opacity: 0,
                duration: 250,
                easing: 'easeInOutBack',
                complete: ()=> {
                    this.imgFlag = this.selectedCounty;
                    anime({
                        // targets: ['.county-name', '.county-img', '.county-brief'],
                        targets: '.top-county-show',
                        scale: 0.75,
                        opacity: 1,
                        duration: 250,
                        easing: 'easeInOutBack',
                    })
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    #system-pane{
        // transform: scale(0.8);
        margin-top: 2.69rem;
        .system-top{
            margin-left: 22.38rem;
            .county-selector{
                width: 70.88rem;
                height: 14.19rem;
                margin-top: 6.81rem;
                transform: scale(0.8);
                .county-selector-container{
                    width: 76.13rem;
                    height: 23.5rem;
                    .dot-container{
                        width: 100%;
                        height: 100%
                    }
                }
            }
        }
        .system-bottom{
            // transform: sc;
            // opacity: ;
            width: 100%;
            height: 29rem;
            margin-top: 2.75rem;
            // https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/comment-document-imgs/document-top-img2.png
            background-image: url("https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/system-botom-bkg.png");
            background-size: cover;
            background-repeat: no-repeat;
            .top-county-show{
                width: 75rem;
                height: 11.25rem;
                background-image: url("https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/矩形.png");
                margin-top: 1.25rem;
                background-size: contain;
                background-repeat: no-repeat;
                
                .county-name{
                    font-size: 4rem;
                    color: #06A560;
                    line-height: 5.94rem;
                    text-align: center;
                    margin-left: 1.94rem;
                    max-width:4em;
                    padding-right:0.5em;
                    // margin-top: 2.63rem;
                }
                .county-brief{
                    width: 42.13rem;
                    height: 2.75rem;
                    font-size: 1.18rem;
                }
                .county-img{
                    // float: right;
                    width: 15.06rem;
                    margin-right: -1px;
                    margin-bottom: -1px;
                    height: 11.27rem;
                }
                @media (max-width: 1024px){
                .county-img{
                    margin-right: -1px;
                    margin-bottom: 0px;
                    height: 11.25rem;
                }
                }
                @media (max-width: 1280px){
                .county-img{
                    margin-right: -1px;
                    margin-bottom: -2px !important;
                    height: 11.25rem;
                }
                }
                @media (max-width: 1360px){
                .county-img{
                    margin-right: -1px;
                    margin-bottom: 0px;
                    height: 11.25rem;
                }
                }
                @media (max-width: 1680px){
                .county-img{
                    margin-right: -1px;
                    // margin-bottom: -1px;
                    height: 11.25rem;
                }
                }
            }
            .bottom-county-show{
                margin-top: 1.06rem;
                width: 69.25rem;
                // transform: scale(0.9);
                .center-img{
                    width: 66.56rem;
                    height: 1.63rem;
                    margin: 0 auto;
                    margin-top: 2.5rem;
                }
                .bottom-nav{
                    width: 16rem;
                    .nav-top{
                        font-size: 1.05rem;
                        color: #fff;
                        margin-bottom: 3.36rem;
                    }
                    .nav-bottom{
                        .bottom-row{
                            font-size: 1rem;
                            color: #fff;
                            line-height: 1.4rem;
                            word-break: keep-all;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
</style>