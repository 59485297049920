<template>
  <div id="home" class="flex-colum">
    <my-header />
    <my-image
      id="top"
      width="100%"
      height="100%"
      :drag="false"
      :staticFlag="true"
      src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/banner.png"
    >
      <div
        class="cric-container default-size flex-colum flex-align-center position-abs"
      >
        <div
          class="cric-container-el flex-row"
          style="width: 54.25rem; height: 14.5rem"
        >
          <div
            class="cric-pane default-size flex-colum-reverse flex-align-start"
          >
            <my-image
              class="btn-action"
              width="10.19rem"
              height="10.19rem"
              :drag="false"
              :lazyload="false"
              src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/banner/gxjz.png"
            />
          </div>
          <div class="cric-pane default-size flex-colum flex-align-end">
            <my-image
              class="btn-action"
              width="12.5rem"
              height="12.5rem"
              :drag="false"
              :lazyload="false"
              src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/banner/pytx.png"
            />
          </div>
        </div>
        <div
          class="cric-container-el flex-row"
          style="width: 76.06rem; height: 14.75rem"
        >
          <div class="cric-pane default-size flex-colum flex-align-start">
            <my-image
              class="btn-action"
              width="12.5rem"
              height="12.5rem"
              :drag="false"
              :lazyload="false"
              src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/banner/xsky.png"
            />
          </div>
          <div class="cric-pane default-size flex-colum flex-align-end">
            <my-image
              class="btn-action"
              width="11.88rem"
              height="11.81rem"
              :drag="false"
              :lazyload="false"
              src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/banner/whhj.png"
            />
          </div>
        </div>
        <div
          class="cric-container-el flex-row"
          style="width: 47.75rem; height: 17.5rem"
        >
          <div class="cric-pane default-size flex-colum flex-align-start">
            <my-image
              class="btn-action"
              width="12.5rem"
              height="12.5rem"
              :drag="false"
              :lazyload="false"
              src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/banner/mxsq.png"
            />
          </div>
          <div
            @click="open"
            class="cric-pane default-size flex-colum flex-align-end cur-pointer"
            id="anime-flag-bounce"
          >
            <my-image
              style="cursor: pointer"
              width="17.5rem"
              height="17.5rem"
              :lazyload="false"
              :drag="false"
              src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/banner/pglql.png"
            />
          </div>
          <my-image
            id="cric-shadow"
            width="13rem"
            height="3.31rem"
            class="position-abs"
            src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/banner/shadow.png"
            style="top: 45.8rem; left: 46.8rem"
          />
        </div>
      </div>
      <div class="text-container position-abs flex-colum flex-align-center">
        <div class="subtitle">Lets Go!</div>
        <div class="title bold-font">海外博士申请</div>
        <div class="title bold-font" style="width: 40rem;margin-left: 1rem;margin-top: 0.3rem">快人一步拿 OFFER</div>
      </div>
    </my-image>
    <div class="advantage normal-padding">
      <my-subtitle text="海外读博有哪些优势？看这些就够了！" index="-sub1" />
      <div id="dbys"></div>
      <advantage />
      <div class="cur-pointer" @click="open" style="width: 100%">
        <my-image
          class=" btn-action"
          width="13.13rem"
          height="3.75rem"
          :lazyload="true"
          style="margin-left: 30rem; margin-top: 1.5rem"
          src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/ljgd-btn.png"
          title="点击咨询"
        ></my-image>
      </div>
    </div>
    <div class="system">
      <system-pane />
    </div>
    <div class="download-strategy flex-colum flex-align-center">
      <div class="free-download-title bold">免费下载热门国家读博攻略</div>
      <div class="free-download-form flex-row flex-center">
        <div
          class="my-input position-rel"
          :style="`${mfhqForm.validation.name ? '' : 'border:1px solid red'}`"
        >
          <span class="label">姓名:</span>
          <input
            v-model.lazy="mfhqForm.name"
            @change="debounce(handelChange(mfhqForm), 100)"
          />
          <span
            class="validationTxt position-abs"
            :style="
              `${
                mfhqForm.validation.name
                  ? ''
                  : 'opacity: 1;transform: translateY(0px) '
              }`
            "
            >请输入您的姓名</span
          >
        </div>
        <div
          class="my-input position-rel"
          :style="`${mfhqForm.validation.phone ? '' : 'border:1px solid red'}`"
        >
          <span class="label">手机号:</span>
          <input
            tyle="number"
            v-model.lazy="mfhqForm.phone"
            maxlength="11"
            @change="debounce(handelChange(mfhqForm), 100)"
          />
          <span
            class="validationTxt position-abs"
            :style="
              `${
                mfhqForm.validation.phone
                  ? ''
                  : 'opacity: 1;transform: translateY(0px) '
              }`
            "
            >请输入正确的手机号</span
          >
        </div>
        <div class="my-input">
          <span class="label">微信号:</span>
          <input v-model.lazy="mfhqForm.wechat" />
        </div>
        <div class="my-input" style="margin-right: 2.13rem;overflow: hidden">
          <span class="label">意向国家:</span>
          <input v-model.lazy="mfhqForm.county" />
        </div>
        <div
          class="cur-pointer"
          @click="debounce(handelSubmit(mfhqForm), 100)"
          style="width: 10.5rem; height: 4.25rem"
        >
          <my-image
            class=" btn-action"
            :lazyload="true"
            src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/mfhq-btn.png"
            title="点击提交"
          />
        </div>
      </div>
    </div>
    <div class="comment-document flex-colum flex-align-center">
      <!-- #表单验证 -->
      <div class="comment-document-title bold">
        博士申请常用文书材料有哪些？
      </div>
      <div class="comment-document-container flex-colum flex-align-center">
        <div class="top-row flex-row flex-jusity-center">
          <nav class="flex-colum">
            <my-image
              class="btn-action"
              width="36.88rem"
              height="17.88rem"
              :lazyload="true"
              src=" https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/comment-document-imgs/document-top-img1.png"
            />
            <span style="width: 33.88rem; height: 2.75rem;"
              >主要包括个人信息、教育背景、学术论文或出版刊物、学术会议、研究经历、获奖经历、专业技能等，展现个人的教育和个人亮点。</span
            >
            <div
              class="nav-btn cur-pointer"
              @click="open"
              style="width: 14.19rem;height: 4rem;"
            >
              <my-image
                class="btn-action"
                :lazyload="true"
                src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/comment-document-imgs/dzwdzsjl-btn.png"
                title="点击咨询"
              />
            </div>
          </nav>
          <nav class="flex-colum" style="margin-left: -2rem;">
            <my-image
              class="btn-action"
              width="36.88rem"
              height="17.88rem"
              :lazyload="true"
              src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/comment-document-imgs/document-top-img2.png"
            />
            <span style="width: 33.56rem; height: 2.75rem;"
              >言简意赅，你的背景、研究方向、申请理由、研究计划等，需要大家在有限篇幅内说清楚自己要表达的意思。</span
            >
            <div
              class="nav-btn cur-pointer"
              @click="open"
              style="width:11.69rem;height:4rem;"
            >
              <my-image
                class=" btn-action"
                :lazyload="true"
                src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/comment-document-imgs/wxyfd-btn.png"
                title="点击咨询"
              />
            </div>
          </nav>
        </div>
        <div class="bottom-row flex-row">
          <nav class="flex-colum">
            <my-image
              class="btn-action"
              width="24rem"
              height="17.88rem"
              :lazyload="true"
              src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/comment-document-imgs/document-bottom-img1.png"
            />
            <span
              >2-3封推荐信，站在推荐人的角度评价学习能力，内容要包括对你的学习成绩、科研能力、合作精神和学术成就等方面的介绍，并且要求有盖章和专用信签纸等。</span
            >
            <div
              class="nav-btn cur-pointer"
              @click="open"
              style="width: 11.88rem;height: 3.88rem"
            >
              <my-image
                class=" btn-action"
                :lazyload="true"
                src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/comment-document-imgs/wxytjx-btn.png"
                title="点击咨询"
              />
            </div>
          </nav>
          <nav class="flex-colum">
            <my-image
              class="btn-action"
              width="24rem"
              height="17.88rem"
              :lazyload="true"
              src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/comment-document-imgs/document-bottom-img2.png"
            />
            <span
              >包含研究问题、与研究问题相关的方法论、最新的参考文献等，字数由学校自行规定。成功的proposal衡量标准是要说明研究内容、目标、现状、研究方法和研究价值，创新性非常重要。</span
            >
            <div
              class="nav-btn cur-pointer"
              @click="open"
              style="width: 11.88rem;height: 3.88rem"
            >
              <my-image
                class=" btn-action"
                :lazyload="true"
                src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/comment-document-imgs/yqzjzd-btn.png"
                title="点击咨询"
              />
            </div>
          </nav>
          <nav class="flex-colum">
            <my-image
              class="btn-action"
              width="24rem"
              height="17.88rem"
              :lazyload="true"
              src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/comment-document-imgs/document-bottom-img3.png"
            />
            <span
              >和国外教授主动通过书信往来建立联系和提升彼此友好印象，进行学术探讨。</span
            >
            <div
              class="nav-btn cur-pointer"
              @click="open"
              style="width: 12.94rem;height: 5.25rem"
            >
              <my-image
                class=" btn-action"
                :lazyload="true"
                src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/comment-document-imgs/tcjqdq-btn.png"
                title="点击咨询"
              />
            </div>
          </nav>
        </div>
      </div>
    </div>
    <div
      v-lazy background-image=""
      class="offer flex-colum bkg"
    >
      <my-subtitle
        style="margin-top:2.63rem;margin-left: 22.56rem;"
        text="如何快速拿到海外名校OFFER?"
        index="-sub2"
        color="#FFFFFF"
      />
      <div id="sbfw"></div>
      <div class="offer-container flex-colum flex-align-center">
        <div
          class="offer-content position-rel flex-colum flex-align-center"
          @mouseenter="enterOfferContent"
        >
          <div class="content-center"></div>
          <div
            style="margin-top: 1.44rem"
            class="nav flex-row position-abs flex-jusity-space-between"
            id="offer-nav-1"
          >
            <div class="nav-content left">
              <span class="txt">前年9月-4月</span>
            </div>
            <div
              class="nav-content right"
              style="width: 43.56rem; height: 5.63rem"
            >
              <my-image
                class="img"
                :lazyload="true"
                src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/offer-img/pops/p9-4.png"
              />
            </div>
          </div>
          <div
            style="margin-top: 10.13rem"
            class="nav flex-row position-abs flex-jusity-space-between"
            id="offer-nav-2"
          >
            <div
              class="nav-content left"
              style="width: 43.19rem; height: 5.63rem"
            >
              <my-image
                class="img"
                :lazyload="true"
                src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/offer-img/pops/4-6.png"
              />
            </div>
            <div class="nav-content right">
              <span class="txt">4月-6月</span>
            </div>
          </div>
          <div
            style="margin-top: 19.13rem"
            class="nav flex-row position-abs flex-jusity-space-between"
            id="offer-nav-3"
          >
            <div class="nav-content left">
              <span class="txt">6月-9月</span>
            </div>
            <div
              class="nav-content right"
              style="width: 43.5rem; height: 5.63rem"
            >
              <my-image
                style="margin-right: 22.81rem"
                class="img"
                :lazyload="true"
                src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/offer-img/pops/6-9.png"
              />
            </div>
          </div>
          <div
            style="margin-top: 29.38rem"
            class="nav flex-row position-abs flex-jusity-space-between"
            id="offer-nav-4"
          >
            <div
              class="nav-content left"
              style="width: 44.19rem; height: 5.63rem"
            >
              <my-image
                style="margin-left: 16.31rem"
                class="img"
                :lazyload="true"
                src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/offer-img/pops/9-10.png"
              />
            </div>
            <div class="nav-content right">
              <span class="txt">9月-10月</span>
            </div>
          </div>
          <div
            style="margin-top: 39.31rem"
            class="nav flex-row position-abs flex-jusity-space-between"
            id="offer-nav-5"
          >
            <div class="nav-content left">
              <span class="txt">10月-11月</span>
            </div>
            <div
              class="nav-content right"
              style="width: 43.44rem; height: 5.63rem"
            >
              <my-image
                style="margin-right: 18.13rem"
                class="img"
                :lazyload="true"
                src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/offer-img/pops/10-11.png"
              />
            </div>
          </div>
          <div
            style="margin-top: 48.5rem"
            class="nav flex-row position-abs flex-jusity-space-between"
            id="offer-nav-6"
          >
            <div
              class="nav-content left"
              style="width: 43.37rem;height: 5.63rem"
            >
              <my-image
                style="margin-left:15.56rem"
                class="img"
                :lazyload="true"
                src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/offer-img/pops/11-n5.png"
              />
            </div>
            <div class="nav-content right">
              <span class="txt">11月-次5月</span>
            </div>
          </div>
          <div
            style="margin-top: 57.25rem"
            class="nav flex-row position-abs flex-jusity-space-between"
            id="offer-nav-1"
          >
            <div class="nav-content left">
              <span class="txt">次5月-11月</span>
            </div>
            <div
              class="nav-content right"
              style="width: 43.38rem; height: 3.88rem"
            >
              <my-image
                style="margin-right: 27.25rem"
                class="img"
                :lazyload="true"
                src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/offer-img/pops/n5-n11.png"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="chose">
      <div class="chose-title bold default-size">
        选择曼博士，你将享受以下服务
      </div>
      <div class="chose-container flex-row flex-jusity-center">
        <div class="chose-container-left">
          <div class="row title">博士留学全程无忧服务</div>
          <div
            class="row flex-row flex-align-center"
            :style="
              `${
                choseNavSelectIndex == 1 ? 'color: #fff;font-size:2.25rem' : ''
              }`
            "
            @mouseenter="choseNavClick(1, 'pggh')"
          >
            评估规划<my-image
              style="margin-left: 0.63rem"
              :src="
                `${
                  choseNavSelectIndex == 1
                    ? 'https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/big-more.png'
                    : 'https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/more.png'
                }`
              "
            />
          </div>
          <div
            class="row flex-row flex-align-center"
            :style="
              `${
                choseNavSelectIndex == 2 ? 'color: #fff;font-size:2.25rem' : ''
              }`
            "
            @mouseenter="choseNavClick(2, 'yhjh')"
          >
            研究计划<my-image
              style="margin-left: 0.63rem"
              :src="
                `${
                  choseNavSelectIndex == 2
                    ? 'https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/big-more.png'
                    :'https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/more.png'
                }`
              "
            />
          </div>
          <div
            class="row flex-row flex-align-center"
            :style="
              `${
                choseNavSelectIndex == 3 ? 'color: #fff;font-size:2.25rem' : ''
              }`
            "
            @mouseenter="choseNavClick(3, 'wsnx')"
          >
            文书撰写<my-image
              style="margin-left: 0.63rem"
              :src="
                `${
                  choseNavSelectIndex == 3
                    ? 'https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/big-more.png'
                    : 'https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/more.png'
                }`
              "
            />
          </div>
          <div
            class="row flex-row flex-align-center"
            :style="
              `${
                choseNavSelectIndex == 4 ? 'color: #fff;font-size:2.25rem' : ''
              }`
            "
            @mouseenter="choseNavClick(4, 'wsfw')"
          >
            网申服务<my-image
              style="margin-left: 0.63rem"
              :src="
                `${
                  choseNavSelectIndex == 4
                    ? 'https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/big-more.png'
                    : 'https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/more.png'
                }`
              "
            />
          </div>
        </div>
        <div id="chose-nav-anime-flag" class="chose-container-right">
          <div
            v-if="choseNavImgs[choseNavSelectIndex - 1].length % 2"
            class="right-img-box flex-colum flex-center"
          >
            <div
              class="nav position-rel"
              style="width: 38.88rem; height: 12.5rem"
              @mouseenter="mouseChoseNavAction(true, 1)"
              @mouseleave="mouseChoseNavAction(false, 1)"
            >
              <my-image
                :lazyload="false"
                :src="`${choseNavImgs[choseNavSelectIndex - 1][0]}`"
              />
              <span id="choseNavSpanTitleAnimeFlag1" class="title bold">{{
                choseNavTxts[choseNavSelectIndex - 1][0].title
              }}</span>
              <span class="text-content1">{{
                choseNavTxts[choseNavSelectIndex - 1][0].text
              }}</span>
            </div>
            <div
              class="nav position-rel"
              style="width: 52rem; height: 12.5rem"
              @mouseenter="mouseChoseNavAction(true, 2)"
              @mouseleave="mouseChoseNavAction(false, 2)"
            >
              <my-image
                :lazyload="false"
                :src="`${choseNavImgs[choseNavSelectIndex - 1][1]}`"
              />
              <span id="choseNavSpanTitleAnimeFlag2" class="title bold">{{
                choseNavTxts[choseNavSelectIndex - 1][1].title
              }}</span>
              <span class="text-content2">{{
                choseNavTxts[choseNavSelectIndex - 1][1].text
              }}</span>
            </div>
            <div
              class="nav position-rel"
              style="width: 65.13rem; height: 12.5rem"
              @mouseenter="mouseChoseNavAction(true, 3)"
              @mouseleave="mouseChoseNavAction(false, 3)"
            >
              <my-image
                :lazyload="false"
                :src="`${choseNavImgs[choseNavSelectIndex - 1][2]}`"
              />
              <span id="choseNavSpanTitleAnimeFlag3" class="title bold">{{
                choseNavTxts[choseNavSelectIndex - 1][2].title
              }}</span>
              <span class="text-content3">{{
                choseNavTxts[choseNavSelectIndex - 1][2].text
              }}</span>
            </div>
          </div>
          <div
            v-if="!(choseNavImgs[choseNavSelectIndex - 1].length % 2)"
            class="right-img-box flex-colum flex-center"
            style=""
          >
            <div
              class="nav-2 flex-row"
              style="width: 45.5rem; height: 18.75rem"
            >
              <div
                class="nav"
                style="width: 22.75rem; height: 18.75rem"
                @mouseenter="
                  mouseChoseNavAction2(true, 1, {
                    enter: { bottom: '8.56rem', left: '5.94rem' },
                    leave: { bottom: '2.44rem', left: '4.14rem' }
                  })
                "
                @mouseleave="
                  mouseChoseNavAction2(false, 1, {
                    enter: { bottom: '8.56rem', left: '5.94rem' },
                    leave: { bottom: '2.44rem', left: '4.14rem' }
                  })
                "
              >
                <my-image
                  :lazyload="false"
                  :src="`${choseNavImgs[choseNavSelectIndex - 1][0][0]}`"
                />
                <span
                  id="choseNavSpanTitleAnimeFlag21"
                  class="title bold"
                  style="left:4.13rem"
                  >{{ choseNavTxts[choseNavSelectIndex - 1][0].title }}</span
                >
                <span class="text-content21">{{
                  choseNavTxts[choseNavSelectIndex - 1][0].text
                }}</span>
              </div>
              <div
                class="nav"
                style="width: 22.75rem; height: 18.75rem"
                @mouseenter="
                  mouseChoseNavAction2(true, 2, {
                    enter: { bottom: '8.56rem', left: '1.94rem' },
                    leave: { bottom: '2.44rem', left: '8.44rem' }
                  })
                "
                @mouseleave="
                  mouseChoseNavAction2(false, 2, {
                    enter: { bottom: '8.56rem', left: '1.94rem' },
                    leave: { bottom: '2.44rem', left: '8.44rem' }
                  })
                "
              >
                <my-image
                  :lazyload="false"
                  :src="`${choseNavImgs[choseNavSelectIndex - 1][0][1]}`"
                />
                <span id="choseNavSpanTitleAnimeFlag22" class="title bold">{{
                  choseNavTxts[choseNavSelectIndex - 1][1].title
                }}</span>
                <span class="text-content22">{{
                  choseNavTxts[choseNavSelectIndex - 1][1].text
                }}</span>
              </div>
            </div>
            <div
              class="nav-2 flex-row"
              style="width: 65.13rem; height: 18.75rem"
            >
              <div
                class="nav"
                style="width: 32.56rem; height: 18.75rem"
                @mouseenter="
                  mouseChoseNavAction2(true, 3, {
                    enter: { bottom: '10.38rem', left: '15.81rem' },
                    leave: { bottom: '1.81rem', left: '4rem' }
                  })
                "
                @mouseleave="
                  mouseChoseNavAction2(false, 3, {
                    enter: { bottom: '10.38rem', left: '15.81rem' },
                    leave: { bottom: '1.81rem', left: '4rem' }
                  })
                "
              >
                <my-image
                  :lazyload="false"
                  :src="`${choseNavImgs[choseNavSelectIndex - 1][1][0]}`"
                />
                <span
                  id="choseNavSpanTitleAnimeFlag23"
                  class="title bold"
                  style="left: 4rem;"
                  >{{ choseNavTxts[choseNavSelectIndex - 1][2].title }}</span
                >
                <span class="text-content23">{{
                  choseNavTxts[choseNavSelectIndex - 1][2].text
                }}</span>
              </div>
              <div
                class="nav"
                style="width: 32.56rem; height: 18.75rem"
                @mouseenter="
                  mouseChoseNavAction2(true, 4, {
                    enter: { bottom: '10.44rem', left: '2rem' },
                    leave: { bottom: '1.88rem', left: '18.85rem' }
                  })
                "
                @mouseleave="
                  mouseChoseNavAction2(false, 4, {
                    enter: { bottom: '10.44rem', left: '2rem' },
                    leave: { bottom: '1.88rem', left: '18.85rem' }
                  })
                "
              >
                <my-image
                  :lazyload="false"
                  :src="`${choseNavImgs[choseNavSelectIndex - 1][1][1]}`"
                />
                <span
                  id="choseNavSpanTitleAnimeFlag24"
                  class="title bold"
                  style="left: 18.25rem;"
                  >{{ choseNavTxts[choseNavSelectIndex - 1][3].title }}</span
                >
                <span class="text-content24">{{
                  choseNavTxts[choseNavSelectIndex - 1][3].text
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="share">
      <my-subtitle
        style="margin-top:7.63rem;margin-left: 18.2rem;"
        text="来自学长学姐的分享"
        index="-sub3"
        color="#333"
      />
      <div id="xyfx"></div>
      <div class="share-container flex-row">
        <div class="share-nav-student-brief flex-row">
          <div class="nav-left">
            <my-image
              class="btn-action"
              id="student-header-img"
              width="17.13rem"
              height="17.13rem"
              :src="`https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/student-share-imgs/student-brief (${studentSwiperCheckIndex}).png`"
            />
          </div>
          <div class="nav-right flex-colum">
            <div class="right-title bold">
              {{ shareTxts[studentSwiperCheckIndex - 1].name }}
            </div>
            <div class="right-text-container flex-colum">
              <span class="first-text" style="width: 13.69rem"
                ><i></i>{{ shareTxts[studentSwiperCheckIndex - 1].offer }}</span
              >
              <span
                style="color: #555;font-size:0.8rem;width:18.5rem"
                :key="index"
                v-for="(item, index) in shareTxts[studentSwiperCheckIndex - 1]
                  .anthor"
                ><i></i>{{ item }}</span
              >
            </div>
          </div>
        </div>
        <div class="share-test-oppor-form flex-colum flex-align-center">
          <!-- #表单验证 -->
          <div
            class="test-oppor-input"
            :style="`${lqjhForm.validation.name ? '' : 'border:1px solid red'}`"
          >
            <span class="label">姓名:</span>
            <input
              type="text"
              v-model.lazy="lqjhForm.name"
              @change="handelChange(lqjhForm)"
            />
          </div>
          <div
            class="test-oppor-input"
            :style="
              `${lqjhForm.validation.phone ? '' : 'border:1px solid red'}`
            "
          >
            <span class="label">手机号:</span>
            <input
              type="text"
              v-model.lazy="lqjhForm.phone"
              @change="handelChange(lqjhForm)"
            />
          </div>
          <div class="test-oppor-input">
            <span class="label">微信号:</span>
            <input type="text" v-model.lazy="lqjhForm.wechat" />
          </div>
          <div class="test-oppor-input" style="overflow: hidden;">
            <span class="label">意向国家:</span>
            <input type="text" v-model.lazy="lqjhForm.county" />
          </div>
          <div class="cur-pointer" @click="handelSubmit(lqjhForm)">
            <my-image
              class=" btn-action"
              :lazyload="true"
              width="17.69rem"
              height="3.23rem"
              src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/cswdlqjh-btn.png"
              title="点击提交"
            />
          </div>
        </div>
        <div class="student-swiper flex-row flex-center">
          <div
            id="student-swiper-left"
            class="left-flag cur-pointer"
            @click="studentSwiperPrev"
          >
            <my-image
              class=" btn-action"
              src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/home-icons/left-cric.png"
            ></my-image>
          </div>
          <swiper
            ref="studentSwiper"
            :options="studentSwiperOptions"
            class="swiper"
          >
            <swiper-slide
              v-for="(item, index) in studentSwiperImgs"
              :key="'swiper-student-' + index"
            >
              <my-image width="9.25rem" height="9.25rem" :src="`${item}`" />
            </swiper-slide>
          </swiper>
          <div
            id="student-swiper-right"
            class="right-flag cur-pointer"
            @click="studentSwiperNext"
          >
            <my-image
              class=" btn-action"
              src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/home-icons/right-cric.png"
            ></my-image>
          </div>
        </div>
      </div>
      <div class="second-title bold">你有疑惑我来解答</div>
      <div class="question-container flex-row">
        <div class="question-nav flex-colum flex-align-center">
          <div class="nav-top">
            <my-image
              class="btn-action"
              :lazyload="true"
              src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/question-nav-imgs/sqjz.png"
            />
          </div>
          <div class="nav-bottom">
            <span>Q：导师制和委员会制有什么区别？</span>
            <span
              >A：导师制通常是导师占主导录取的意见，获取导师的认可很重要。委员会制通常会对申请者基础条件要求比较严格，不符合基本条件的申请者有比较大的概率被拒绝。</span
            >
          </div>
        </div>
        <div class="question-nav flex-colum">
          <div class="nav-top">
            <my-image
              class="btn-action"
              :lazyload="true"
              src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/question-nav-imgs/kybj.png"
            />
          </div>
          <div class="nav-bottom">
            <span>Q：博士申请一定要有论文发表吗？</span>
            <span>A：论文发表通常不是硬性要求，当然有论文更具优势。</span>
          </div>
        </div>
        <div class="question-nav flex-colum">
          <div class="nav-top">
            <my-image
              class="btn-action"
              :lazyload="true"
              src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/question-nav-imgs/yycj.png"
            />
          </div>
          <div class="nav-bottom">
            <span>Q：博士申请的语言成绩是什么时候提交？</span>
            <span>A：多数时候，只需要在入学前提交即可（con-offer）</span>
            <span>Q：欧洲博士申请一定要小语种成绩吗？</span>
            <span
              >A：多数欧洲国家博士申请只需英语成绩即可，多数小语种国家也只用英语成绩申请。</span
            >
          </div>
        </div>
        <div class="question-nav flex-colum">
          <div class="nav-top">
            <my-image
              class="btn-action"
              :lazyload="true"
              src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/question-nav-imgs/sqzd.png"
            />
          </div>
          <div class="nav-bottom">
            <span>Q：申请的时候一定要拿到硕士学位吗？</span>
            <span>A：在硕士在读的时候即可开始申请，以实现无gap入学。</span>
          </div>
        </div>
      </div>
      <div
        class="question-btn flex-colum flex-align-center cur-pointer"
        @click="open"
      >
        <my-image
          width="13.31rem"
          height="3rem"
          :lazyload="true"
          class=" btn-action"
          src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/question-nav-imgs/kqwdsbzl-btn.png"
          title="点击咨询"
        />
      </div>
    </div>
    <div class="dream flex-colum flex-align-center">
      <div class="title default-size">
        <my-subtitle
          style="margin-left: 21.5rem"
          text="选择曼博士，把梦想实现"
          index="-sub4"
        />
        <div id="xzmh"></div>
      </div>
      <my-image
        width="18.44rem"
        height="5.25rem"
        :lazyload="true"
        src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/power-imgs/title-1.png"
      />
      <div class="power-container flex-colum flex-align-center default-size">
        <div class="power-nav-top flex-row flex-jusity-center">
          <my-image
            class="btn-action"
            style="margin-right: 0.81rem"
            width="18.13rem"
            height="9.5rem"
            :lazyload="true"
            src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/power-imgs/zyfx.png"
          />
          <my-image
            class="btn-action"
            style="margin-right: 0.81rem"
            width="18.13rem"
            height="9.5rem"
            :lazyload="true"
            src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/power-imgs/xsds.png"
          />
          <my-image
            class="btn-action"
            style="margin-right: 0.81rem"
            width="18.13rem"
            height="9.5rem"
            :lazyload="true"
            src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/power-imgs/hwmx.png"
          />
          <my-image
            class="btn-action"
            style="margin-right: 0.81rem"
            width="18.13rem"
            height="9.5rem"
            :lazyload="true"
            src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/power-imgs/yjly.png"
          />
        </div>
        <div class="power-nav-bottom flex-row flex-center">
          <div class="power-left" @click="powerSwiperPrev">
            <my-image
              class=" btn-action"
              src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/home-icons/big-left-cric.png"
            ></my-image>
          </div>
          <swiper
            v-if="powerSwiperImgs.length > 1"
            :key="'power-swiper-1'"
            class="power-swiper"
            ref="powerSwiper"
            :options="powerSwiperOptions"
          >
            <swiper-slide
              class="powier-swiper-item"
              v-for="(item, index) in powerSwiperImgs"
              :key="'power-index-' + index"
            >
              <my-image style="margin-right: 1.88rem" :src="`${item}`" />
            </swiper-slide>
          </swiper>
          <div class="power-right" @click="powerSwiperNext">
            <my-image
              class=" btn-action"
              src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/home-icons/big-right-cric.png"
            ></my-image>
          </div>
        </div>
      </div>
    </div>
    <div class="team-advantage flex-colum flex-align-center">
      <div class="title">
        <my-image
          :lazyload="true"
          src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/power-imgs/title-2.png"
        />
      </div>
      <div class="team-advantage-container flex-row flex-jusity-center">
        <my-image
          class="tem-advantage-img"
          :lazyload="true"
          src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/power-imgs/content2-1.png"
        />
        <my-image
          class="tem-advantage-img"
          :lazyload="true"
          src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/power-imgs/content2-2.png"
        />
        <my-image
          class="tem-advantage-img"
          :lazyload="true"
          src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/power-imgs/content2-3.png"
        />
        <my-image
          class="tem-advantage-img"
          :lazyload="true"
          src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/power-imgs/content2-4.png"
        />
        <my-image
          class="tem-advantage-img"
          :lazyload="true"
          src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/power-imgs/content2-5.png"
        />
      </div>
    </div>
    <div class="wwyt-title flex-colum flex-align-center">
      <my-image
        src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/power-imgs/title-3.png"
      ></my-image>
    </div>
    <wwyt style="margin: 0 auto;margin-top: 13.38rem;margin-bottom: 8.69rem;" />
    <div class="map-title flex-colum flex-align-center">
      <my-image
        src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/power-imgs/title-4.png"
      ></my-image>
    </div>
    <map-action />
    <div class="programme">
      <div class="programme-title flex-row flex-align-center">
        <div class="line"></div>
        <div class="title bold">免费定制我的申博方案</div>
        <div class="line"></div>
      </div>
      <div class="programme-container flex-row flex-jusity-center">
        <div
          class="programm-input position-rel"
          :style="`${getlqvForm.validation.name ? '' : 'border:1px solid red'}`"
        >
          <span class="label">姓名:</span>
          <input
            type="text"
            v-model.lazy="getlqvForm.name"
            @change="handelChange(getlqvForm)"
          />
          <span
            class="validationTxt position-abs"
            :style="
              `${
                getlqvForm.validation.name
                  ? ''
                  : 'opacity: 1;transform: translateY(0px) '
              }`
            "
            >请输入您的姓名</span
          >
        </div>
        <div
          class="programm-input position-rel"
          :style="
            `${getlqvForm.validation.phone ? '' : 'border:1px solid red'}`
          "
        >
          <span class="label">手机号:</span>
          <input
            type="text"
            v-model.lazy="getlqvForm.phone"
            @change="handelChange(getlqvForm)"
          />
          <span
            class="validationTxt position-abs"
            :style="
              `${
                getlqvForm.validation.phone
                  ? ''
                  : 'opacity: 1;transform: translateY(0px) '
              }`
            "
            >请输入正确的手机号</span
          >
        </div>
        <div class="programm-input" style="overflow: hidden;">
          <span class="label">微信号:</span>
          <input type="text" v-model.lazy="getlqvForm.wechat" />
        </div>
        <div class="programm-input" style="margin-right: 2.13rem;overflow: hidden;">
          <span class="label">意向国家:</span>
          <input type="text" v-model.lazy="getlqvForm.county" />
        </div>
        <div class="cur-pointer" @click="handelSubmit(getlqvForm)">
          <my-image
            class=" btn-action"
            width="10.5rem"
            height="4.25rem"
            src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/lqv-btn.png"
            title="点击提交"
          />
        </div>
      </div>
    </div>
    <div class="suspension">
      <div class="navigation flex-colum">
        <div id="icon" @click="iconClick"></div>
        <span
          :class="
            `navigation-span ${selectTags == 'dbys' ? 'span-select' : ''}`
          "
          @click="toTop('dbys')"
          >读博优势</span
        >
        <span
          :class="
            `navigation-span ${selectTags == 'dbtx' ? 'span-select' : ''}`
          "
          @click="toTop('dbtx')"
          >读博体系</span
        >
        <span
          :class="
            `navigation-span ${selectTags == 'sbfw' ? 'span-select' : ''}`
          "
          @click="toTop('sbfw')"
          >申博服务</span
        >
        <span
          :class="
            `navigation-span ${selectTags == 'xyfx' ? 'span-select' : ''}`
          "
          @click="toTop('xyfx')"
          >学员分享</span
        >
        <span
          :class="
            `navigation-span ${selectTags == 'xzmh' ? 'span-select' : ''}`
          "
          @click="toTop('xzmh')"
          >选择曼博士</span
        >
      </div>
      <div
        v-show="toTopShowFlag"
        class="toTop flex-colum flex-align-center"
        @click="top()"
      >
        <div class="icon"></div>
        <span>回到顶部</span>
      </div>
      <div
        class="service flex-colum flex-align-center cur-pointer"
        @click="open"
      >
        <div class="icon"></div>
        <span>小曼博士</span>
      </div>
    </div>
    <my-footer />
  </div>
</template>

<script>
import MyHeader from "@components/MyHeader";
import MyFooter from "@components/MyFooter";
import MyImage from "@components/MyImage";
import Advantage from "@components/Advantage";
import MySubtitle from "@components/MySubtitle";
import SystemPane from "@components/SystemPane";
import wwyt from "@components/wwyt";
import MapAction from "@components/MapAction";

import anime from "animejs/lib/anime.es.js";
import { start } from "repl";
export default {
  name: "Home",
  data() {
    return {
      toTopShowFlag: false,
      selectTags: "",
      enterOfferActionFlag: true,
      studentSwiperCheckIndex: 1,
      xunfuClickNum: 1,
      studentSwiperOptions: {
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        autoplay: false,
        speed: 800,
        direction: "horizontal",
        grabCursor: true,
        effect: "fade",
        // loop: true,
        on: {
          slideChangeTransitionStart: e => {
            let studentImgAction = anime({
              targets: "#student-header-img",
              translateX: "-3rem",
              opacity: 0,
              duration: 350,
              easing: "easeInOutBack",
              complete: () => {
                this.studentSwiperCheckIndex = e.activeIndex + 1;
                anime({
                  targets: "#student-header-img",
                  translateX: 0,
                  opacity: 1,
                  duration: 250,
                  easing: "easeInOutBack"
                });
              }
            });
          }
        }
      },
      studentSwiperImgs: [
         "https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/student-share-imgs/student-brief (1).png",
         "https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/student-share-imgs/student-brief (2).png",
         "https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/student-share-imgs/student-brief (3).png",
         "https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/student-share-imgs/student-brief (4).png",
         "https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/student-share-imgs/student-brief (5).png",
         "https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/student-share-imgs/student-brief (6).png",
         "https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/student-share-imgs/student-brief (7).png",
         "https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/student-share-imgs/student-brief (8).png",
         "https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/student-share-imgs/student-brief (9).png",
      ],
      powerSwiperOptions: {
        // noNextTick: true,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        autoplay: {
          delay: 3000, //秒
          stopOnLastSlide: false,
          disableOnInteraction: false //滑动不会失效
        },
        initialSlide: 1,
        slidesPerView: 3,
        centeredSlides: true,
        grabCursor: true,
        loop: true,
        loopFillGroupWithBlank: true
      },
      powerSwiperImgs: [
         "https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/power-imgs/swiper-imgs/power-swiper-item (1).png",
         "https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/power-imgs/swiper-imgs/power-swiper-item (2).png",
         "https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/power-imgs/swiper-imgs/power-swiper-item (3).png",
         "https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/power-imgs/swiper-imgs/power-swiper-item (4).png",
         "https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/power-imgs/swiper-imgs/power-swiper-item (5).png",
         "https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/power-imgs/swiper-imgs/power-swiper-item (6).png",
         "https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/power-imgs/swiper-imgs/power-swiper-item (7).png",
         "https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/power-imgs/swiper-imgs/power-swiper-item (8).png",
         "https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/power-imgs/swiper-imgs/power-swiper-item (9).png",
         "https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/power-imgs/swiper-imgs/power-swiper-item (10).png",
         "https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/power-imgs/swiper-imgs/power-swiper-item (11).png",
         "https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/power-imgs/swiper-imgs/power-swiper-item (12).png",
         "https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/power-imgs/swiper-imgs/power-swiper-item (13).png",
         "https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/power-imgs/swiper-imgs/power-swiper-item (14).png",
         "https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/power-imgs/swiper-imgs/power-swiper-item (15).png",
         "https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/power-imgs/swiper-imgs/power-swiper-item (16).png"
      ],
      choseNavSelectIndex: 1,
      choseNavSelectName: "pggh",
      enterAnimeFlag: true,
      leaveAnimeFlag: true,
      choseNavTxts: [
        [
          {
            title: "全面背景评估",
            text:
              "针对学生的学术背景，个人条件，专业概括，就业方向等多方面作出全方位的精准定位和意见评估"
          },
          {
            title: "背景提升指导",
            text:
              "致力于不同背景的学生，在论文发表、科研项目、实践学术积累等多维度制定合理的留学提升方案，挖掘学生潜在竞争力和价值，提升申请背景"
          },
          {
            title: "申请进度规划",
            text:
              "为学生提供从前期升学到后期入学的全程时间的合理规划方向，以及各类事项衔接的流程安排"
          }
        ],
        [
          {
            title: "院校导师匹配",
            text:
              "根据学生学术背景规划，选校偏好及个人竞争力，配合各院校录取偏好和师资水平，为学生提供专业院校和导师选择，制定适合学生的专属方案"
          },
          {
            title: "研究计划课题制定",
            text:
              "根据学生专业方向，匹配同领域的博士Tutor，梳理可选研究方向的进展和前景，讨论初步的研究方向和类型，进行一对一的课题制定"
          },
          {
            title: "研究计划写作辅导",
            text:
              "根据意向课题方向，博士Tutor给学生推荐Reading Lists，同时亲自指导学生进行有效的文献阅读和综述撰写，以及定稿研究计划的完成"
          },
          {
            title: "研究计划专业润色",
            text:
              "毕业于常青藤盟校等知名大学的外籍顾问老师亲自对学生的研究计划进行语言修饰润色和指导"
          }
        ],
        [
          {
            title: "个人陈述撰写",
            text:
              "根据学生提供的背景材料，确定文书提纲，头脑风暴提炼学生亮点，展示与众不同的能力和经历，确保架构完整，逻辑清晰，完成突出学生优势的陈述撰写"
          },
          {
            title: "学术简历撰写",
            text:
              "根据学生提供的背景材料，搭建简历框架，优化结构素材，表现专业水平，润色语言表达等，撰写出一份突出学生特色的学术简历"
          },
          {
            title: "推荐信撰写",
            text:
              "根据学生提供的推荐人进行筛选，获取推荐信策略，分析并确定每位推荐人的侧重方向，进行差异化的撰写及润色表达，最终供推荐人确定"
          },
          {
            title: "套磁信制作",
            text:
              "根据学生背景和导师方向，指导筛选合适教授，定制学生专属套磁结构及内容，并对套磁信整体进行指导和润色，保证沟通的礼仪和措辞得体"
          }
        ],
        [
          {
            title: "网申表格填写",
            text:
              "根据学生选校方案，提供一系列网申流程指导及问题答疑，同时针对部分院校网申差异为学生填写网申表格"
          },
          {
            title: "模拟面试指导",
            text:
              "为学生提供往届面试题库、专业导师亲身分享面经以及一对一的礼仪辅导培训，面试题目回答的完善"
          },
          {
            title: "协助入学服务",
            text:
              "协助学生根据自身优势及专业就业方向进行offer选择，签证材料准备和后期入学流程指导等工作"
          }
        ]
      ],
      choseNavImgs: [
        [
           "https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/chose-img/chose-1/top.png",
           "https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/chose-img/chose-1/center.png",
           "https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/chose-img/chose-1/bottom.png"
        ],
        [
          [
             "https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/chose-img/chose-3/top-left.png",
             "https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/chose-img/chose-3/top-right.png"
          ],
          [
             "https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/chose-img/chose-3/bottom-left.png",
             "https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/chose-img/chose-3/bottom-right.png"
          ]
        ],
        [
          [
             "https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/chose-img/chose-4/top-left.png",
             "https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/chose-img/chose-4/top-right.png"
          ],
          [
             "https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/chose-img/chose-4/bottom-left.png",
             "https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/chose-img/chose-4/bottom-right.png"
          ]
        ],
        [
           "https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/chose-img/chose-2/top.png",
           "https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/chose-img/chose-2/center.png",
           "https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/chose-img/chose-2/bottom.png"
        ]
      ],
      shareTxts: [
        {
          name: "Brenda",
          offer: "西交利物浦大学博士offer",
          anthor: [
            "伯明翰大学财务管理硕士",
            "西交利物浦大学金融数学学士",
            "研究生一等学位"
          ]
        },
        {
          name: "Yvonne",
          offer: "爱丁堡大学全奖博士offer",
          anthor: [
            "西交利物浦和利物浦大学2+2金融数学学士",
            "LSE运筹学与分析硕士",
            "硕士均分86"
          ]
        },
        {
          name: "Philip",
          offer: "爱丁堡大学博士offer",
          anthor: ["本科绩点3.4，硕士均分74", "布里斯托大学会计硕士"]
        },
        {
          name: "Wendy",
          offer: "曼彻斯特大学 Business and Management offer",
          anthor: ["国内普通一本大学学士；利兹大学硕士", "本科GPA：82，硕士Merit"]
        },
        {
          name: "Xandy",
          offer: "帝国理工大学临床医学方向博士offer",
          anthor: ["卡迪夫硕士在读", "硕士均分86英硕 pass (均分50+）"]
        },
        {
          name: "Harrison",
          offer: "拉夫堡大学艺术博士offer",
          anthor: [
            "华中农业大学学士；中国地质大学硕士",
            "本科GPA：3.68，硕士均分89.26"
          ]
        },
        {
          name: "Hayden",
          offer: "杜伦大学地质学博士offer",
          anthor: ["国内普通一本院校，本科直博", "本硕绩点均分70"]
        },
        {
          name: "Winola",
          offer: "香港科技大学博士offer",
          anthor: ["985硕士本科直博，GPA3.62，托福100", "本科GPA：3.62"]
        },
        {
          name: "Tania",
          offer: "皇家霍洛威management department offer",
          anthor: ["国内211大学学士；伦敦国王学院硕士", "硕士Pass"]
        }
      ],
      mfhqForm: {
        name: "",
        phone: "",
        wechat: "",
        county: "",
        validation: {
          name: true,
          phone: true
        }
      },
      lqjhForm: {
        name: "",
        phone: "",
        wechat: "",
        county: "",
        validation: {
          name: true,
          phone: true
        }
      },
      getlqvForm: {
        name: "",
        phone: "",
        wechat: "",
        county: "",
        validation: {
          name: true,
          phone: true
        }
      }
    };
  },
  components: {
    MyHeader,
    MyFooter,
    MyImage,
    Advantage,
    MySubtitle,
    SystemPane,
    wwyt,
    MapAction
  },
  mounted() {
    this.loopAction();
    var _slef = this;
    // this.throttle(()=> {
    this.$nextTick(() => {
      setInterval(() => {
        this.$refs.powerSwiper.$swiper.slideNext(800, true);
      }, 6000);
    });
    // },100);
    var scrollFun = _slef.debounce(function() {
      let $elOffSetTop1 = document.querySelector("#dbys").offsetTop;
      let $elOffSetTop2 = document.querySelector("#dbtx").offsetTop;
      let $elOffSetTop3 = document.querySelector("#sbfw").offsetTop;
      let $elOffSetTop4 = document.querySelector("#xyfx").offsetTop;
      let $elOffSetTop5 = document.querySelector("#xzmh").offsetTop;
      let $scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
      if ($scrollTop != 0) {
        if (
          $elOffSetTop1 - $scrollTop <= 600 &&
          $elOffSetTop1 - $scrollTop > -800
        ) {
          _slef.selectTags = "dbys";
        }
        if (
          $elOffSetTop2 - $scrollTop <= 600 &&
          $elOffSetTop2 - $scrollTop > -800
        ) {
          _slef.selectTags = "dbtx";
        }
        if (
          $elOffSetTop3 - $scrollTop <= 600 &&
          $elOffSetTop3 - $scrollTop > -800
        ) {
          _slef.selectTags = "sbfw";
        }
        if (
          $elOffSetTop4 - $scrollTop <= 600 &&
          $elOffSetTop4 - $scrollTop > -800
        ) {
          _slef.selectTags = "xyfx";
        }
        if (
          $elOffSetTop5 - $scrollTop <= 600 &&
          $elOffSetTop5 - $scrollTop > -800
        ) {
          _slef.selectTags = "xzmh";
        }
      } else {
        _slef.selectTags = "";
      }
      if (!_slef.toTopShowFlag) {
        if ((document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop) > 500) {
          _slef.toTopShowFlag = true;
        }
      }
      if (_slef.toTopShowFlag) {
        if ((document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop) <= 500) {
          _slef.toTopShowFlag = false;
        }
      }
    }, 100);
    window.addEventListener("scroll", scrollFun, true);
  },
  methods: {
    debounce(func, wait, immediate) {
      var timeout, args, context, timestamp, result;
      var later = function() {
        // 据上一次触发时间间隔
        var last = new Date() - timestamp;
        // 上次被包装函数被调用时间间隔last小于设定时间间隔wait
        if (last < wait && last > 0) {
          timeout = setTimeout(later, wait - last);
        } else {
          timeout = null;
          // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
          if (!immediate) {
            result = func.apply(context, args);
            if (!timeout) context = args = null;
          }
        }
      };

      return function() {
        context = this;
        args = arguments;
        timestamp = new Date();
        var callNow = immediate && !timeout;
        // 如果延时不存在，重新设定延时
        if (!timeout) timeout = setTimeout(later, wait);
        if (callNow) {
          result = func.apply(context, args);
          context = args = null;
        }

        return result;
      };
    },
    throttle(func, wait, options) {
      // 上下文，函数参数，函数返回值
      var context, args, result;
      // 延时器
      var timeout = null;
      // 上一次执行的func的时间点
      var previous = 0;
      if (!options) options = {};
      // 延时执行函数
      var later = function() {
        // 如果及时调用被关闭，则设置previous为0
        previous = options.leading === false ? 0 : new Date();
        timeout = null;
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      };
      /** 以上变量以及函数都是通过闭包的方式访问的 **/
      return function() {
        var now = new Date();
        if (!previous && options.leading === false) previous = now;
        // remaining容易理解，表示还剩多少时间可以再次执行func
        var remaining = wait - (now - previous);
        // 保存上下文
        context = this;
        // 获取函数参数
        args = arguments;
        // 及时模式
        // remaining小于等于0是跳出wait的限制，可以执行了
        // remaining大于wait的情况，只有在客户机修改了系统时间的时候才会出现
        // 这两种情况都可以立刻对func做调用
        if (remaining <= 0 || remaining > wait) {
          // 清除定时器
          if (timeout) {
            clearTimeout(timeout);
            timeout = null;
          }
          previous = now;
          result = func.apply(context, args);
          if (!timeout) context = args = null;
        } else if (!timeout && options.trailing !== false) {
          // 延时模式
          timeout = setTimeout(later, remaining);
        }
        return result;
      };
    },
    loopAction() {
      let _slef = this;
      let bounceAction = anime
        .timeline({
          targets: "#anime-flag-bounce",
          translateY: ["6rem", "8rem"],
          duration: 1000,
          loop: true,
          direction: "alternate",
          easing: "easeInCubic",
          //new code
          scaleX: {
            value: 1.05, //代表缩放的值
            duration: 300, // 运行时间
            delay: 750 //延迟多久执行
          }
        })
        .add({
          targets: "#cric-shadow",
          scale: [0.6, 1],
          opacity: [0.3, 1],
          direction: "alternate",
          easing: "easeInCubic"
        });
    },
    studentSwiperPrev(e) {
      this.$nextTick(() => {
        if (this.$refs.studentSwiper.$swiper.activeIndex) {
          this.$refs.studentSwiper.$swiper.slidePrev(800, true);
        } else {
          this.$refs.studentSwiper.$swiper.slideTo(8, 400, true);
        }
      });
    },
    studentSwiperNext(e) {
      this.$nextTick(() => {
        if (this.$refs.studentSwiper.$swiper.activeIndex >= 8) {
          this.$refs.studentSwiper.$swiper.slideTo(0, 400, true);
        } else {
          this.$refs.studentSwiper.$swiper.slideNext(800, true);
        }
      });
    },
    powerSwiperPrev() {
      this.$refs.powerSwiper.$swiper.slidePrev(800, true);
    },
    powerSwiperNext() {
      this.$refs.powerSwiper.$swiper.slideNext(800, true);
    },
    choseNavClick(selectIndex, selectName) {
      if (this.choseNavSelectIndex != selectIndex) {
        let choseNavAction = anime({
          targets: "#chose-nav-anime-flag",
          opacity: 0,
          duration: 150,
          easing: "easeInOutBack",
          complete: () => {
            this.choseNavSelectIndex = selectIndex;
            this.choseNavSelectName = selectName;
            anime({
              // targets: ['.county-name', '.county-img', '.county-brief'],
              targets: "#chose-nav-anime-flag",
              opacity: 1,
              duration: 400,
              easing: "easeInOutBack"
            });
          }
        });
      } else {
        return;
      }
    },
    mouseChoseNavAction(flag, index) {
      if (flag) {
        let enterChoseNavAction = anime.timeline({
          targets: "#choseNavSpanTitleAnimeFlag" + index,
          translateY: -100,
          duration: 400,
          easing: "easeInOutBack"
        });
        enterChoseNavAction.add(
          {
            targets: ".text-content" + index,
            translateY: [20, 0],
            opacity: 1,
            easing: "easeInOutBack"
          },
          100
        );
      } else {
        let leaveChoseNavAction = anime.timeline({
          targets: "#choseNavSpanTitleAnimeFlag" + index,
          translateY: 0,
          duration: 400,
          easing: "easeInOutBack"
        });
        leaveChoseNavAction.add(
          {
            targets: ".text-content" + index,
            translateY: [0, 20],
            opacity: 0,
            easing: "easeInOutBack"
          },
          100
        );
      }
    },
    mouseChoseNavAction2(flag, index, positionData) {
      if (flag) {
        let enterChoseNavAction = anime.timeline({
          targets: "#choseNavSpanTitleAnimeFlag2" + index,
          bottom: positionData.enter.bottom,
          left: positionData.enter.left,
          duration: 500,
          easing: "easeInOutBack"
        });
        enterChoseNavAction.add(
          {
            targets: ".text-content2" + index,
            translateY: [20, 0],
            opacity: 1,
            easing: "easeInOutBack"
          },
          100
        );
      } else {
        let leaveChoseNavAction = anime.timeline({
          targets: "#choseNavSpanTitleAnimeFlag2" + index,
          bottom: positionData.leave.bottom,
          left: positionData.leave.left,
          duration: 500,
          easing: "easeInOutBack"
        });
        leaveChoseNavAction.add(
          {
            targets: ".text-content2" + index,
            translateY: [0, 20],
            opacity: 0,
            easing: "easeInOutBack"
          },
          100
        );
      }
    },
    enterOfferContent() {
      if (this.enterOfferActionFlag) {
        this.enterOfferActionFlag = false;
        this.enterOfferAction = anime({
          targets: ".nav-content",
          opacity: 0,
          duration: 1,
          complete: () => {
            let tempAnime = anime({
              targets: ".content-center",
              keyframes: [
                { height: "0rem" },
                { height: "7.03rem" },
                { height: "15.94rem" },
                { height: "24.76rem" },
                { height: "34.01rem" },
                { height: "44.94rem" },
                { height: "54.13rem" },
                { height: "65.25rem" }
              ],
              duration: 3000,
              complete: () => {
                this.enterOfferActionFlag = true;
              }
            });
            anime({
              targets: ".nav-content",
              translateY: [-20, 0],
              opacity: 1,
              delay: anime.stagger(187, { start: 375 })
            });
          }
        });
      }
    },
    iconClick() {
      anime
        .timeline({
          targets: "#icon",
          rotate: () => {
            return this.xunfuClickNum % 2 == 0 ? 0 : 180;
          },
          duration: 220,
          easing: "linear"
        })
        .add({
          targets: ".navigation",
          height: () => {
            return this.xunfuClickNum % 2 != 0 ? "0rem" : "10rem";
          }
          // duration: 200,
        })
        .add({
          targets: ".navigation-span",
          opacity: () => {
            return this.xunfuClickNum % 2 != 0 ? 0 : 1;
          },
          height: () => {
            return this.xunfuClickNum % 2 != 0 ? "0rem" : "1.94rem";
          },
          // duration: 300,
          // delay: 100,
          complete: () => {
            this.xunfuClickNum++;
          }
        });
    },
    toTop(index) {
      this.throttle(
        this.$nextTick(function() {
          document.documentElement.scrollTop =
            document.getElementById(index).offsetTop - 160;
          this.selectTags = index;
        }),
        100
      );
    },
    top() {
      this.$nextTick(() => {
        document.getElementById("top").scrollIntoView({ behavior: "smooth" });
      });
    },
    open() {
      openJesongChatByGroup(34320,48226);
      return false;
    },
    handelChange(obj) {
      let phonereg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      let namereg = /^[\u4E00-\u9FA5A-Za-z\s]+(·[\u4E00-\u9FA5A-Za-z]+)*$/;
      if (!namereg.test(obj.name)) {
        obj.validation.name = false;
      } else {
        obj.validation.name = true;
      }
      if (!phonereg.test(obj.phone)) {
        obj.validation.phone = false;
      } else {
        obj.validation.phone = true;
      }
    },
    handelSubmit(obj) {
      this.handelChange(obj);
      if (obj.validation.name && obj.validation.phone) {
        let data = new FormData();
        data.append("data[name]", obj.name);
        data.append("data[tel]", obj.phone);
        data.append("data[wechat_no]", obj.wechat);
        data.append("data[intention_area]", obj.county);
        this.axios
          .post("/index.php?c=index&a=ajax&modelid=21", data)
          .then(res => {
            // console.log("11111",res)
            confirm(res.data.msg);
          });
      } else {
        return;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.bkg{
  background-image:url('https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/offer-bkg.png');
  background-size: cover;
  background-repeat: no-repeat;
}
.validationTxt {
  bottom: -1.5rem;
  left: 0.3rem;
  color: red;
  transform: translateY(-20px);
  opacity: 0;
  transition: all 0.3s;
}
.my-input {
  width: 15.13rem;
  height: 4.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #C2C7E2;
  border-radius: 0.5rem;
  margin-right: 0.63rem;
  background: white;
  // overflow: hidden;
  .label {
    color: #666;
    letter-spacing: 0.06rem;
    line-height: 1.38rem;
    margin-left: 0.86rem;
    margin-right: 0.2rem;
    word-break: keep-all;
  }
  input {
    width: 9rem;
    border: none;
    outline: medium;
    font-size: 1.1rem;
    color: #333;
  }
}
.normal-padding {
  padding-left: 22.5rem;
}
#anime-flag-bounce {
  // transform: translateY(-20rem);
  margin-top: -8rem;
  z-index: 1;
}
#home {
  overflow: hidden;
  #top {
    height: 67.5rem;
  }
  .cric-container {
    width: 76.06rem;
    height: 52.75rem;
    left: 22.44rem;
    top: 11.56rem;
  }
  .text-container {
    color: white;
    top: 24.13rem;
    left: 40.63rem;
    margin: auto;
    text-align: center;
    width: 38.74rem;
    height: 16.38rem;
    .subtitle {
      font-size: 3.88rem;
      // color: #A6B6F8;
      color: #FFFFFF;
      line-height: 4.44rem;
      text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.34);
      user-select: none;
    }
    .title {
      font-size: 4.5rem;
      // color: #A6B6F8;
      color: #FFFFFF;
      line-height: 5.25rem;
      text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.34);
      user-select: none;
    }
  }
  .advantage {
    margin-top: 5.94rem;
  }
  .download-strategy {
    height: 24.19rem;
    width: 100%;
    background: rgba(240, 242, 248, 0.5);
    // opacity:0.5;
    .free-download-title {
      font-size: 2.19rem;
      color: #333;
      line-height: 3.06rem;
      margin-top: 7.19rem;
    }
    .free-download-form {
      margin-top: 3.38rem;
    }
  }
  .comment-document {
    .comment-document-title {
      font-size: 2.19rem;
      color: #333333;
      line-height: 3.06rem;
      margin-top: 4.56rem;
    }
    .comment-document-container {
      .top-row {
        margin-top: 1.88rem;
        nav {
          width: 37.5rem;
          height: 27.49rem;
          span {
            display: inline-block;
            font-size: 0.88rem;
            line-height: 1.38rem;
            color: #222222;
            margin-left: 0.83rem;
            // margin-top: -0.48rem;
          }
          .nav-btn {
            margin-top: 0.63rem;
          }
        }
      }
      .bottom-row {
        nav {
          width: 24.69rem;
          height: 29.88rem;
          margin-left: -1rem;
          margin-top: -0.2rem;
          span {
            display: inline-block;
            font-size: 0.88rem;
            line-height: 1.38rem;
            color: #222222;
            width: 22.19rem;
            height: 4.5rem;
            // margin-top: -0.7rem;
            margin-left: 0.9rem;
          }
          .nav-btn {
            margin-top: 1.44rem;
          }
        }
      }
    }
  }
  .offer {
    width: 100%;
    margin: 0 auto;
    height: 60.19rem;
    margin-top: 5.25rem;
    background-size: cover;
    .offer-container {
      width: 100%;
      height: 100%;
      margin-top: -4.19rem;
      transform: scale(0.76);
      .offer-content {
        width: 90.81rem;
        height: 65.25rem;
        .nav {
          width: 100%;
          height: auto;
          .left {
            .txt {
              font-size: 1.43rem;
              margin-left: 32.81rem;
              margin-top: 0.63rem;
              word-break: keep-all;
            }
            .img {
              margin-left: 21rem;
            }
          }
          .right {
            .txt {
              font-size: 1.43rem;
              margin-right: 34.94rem;
              margin-top: 0.38rem;
              word-break: keep-all;
            }
            .img {
              margin-right: 22.5rem;
            }
          }
          .nav-content {
            span {
              font-weight: bold;
              font-size: 1.13rem;
              color: #fff;
              line-height: 1.56rem;
              display: block;
            }
          }
        }
        .content-center {
          width: 0.88rem;
          // height: 61.25rem;
          height: 65.25rem;
          background-image: url("https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/offer-img/timeline.png");
          // background-repeat: no-repeat;
          // background-size: cover;
          // object-fit: cover;
        }
      }
    }
  }

  .share {
    .share-container {
      margin-left: 22.69rem;
      margin-top: 4.69rem;
      .share-nav-student-brief {
        .nav-right {
          margin-left: 1.31rem;
          margin-top: 0.44rem;
          .right-title {
            font-size: 1.5rem;
            color: #333333;
            line-height: 2.06rem;
          }
          .right-text-container {
            margin-top: 2.56rem;
            .first-text {
              font-weight: bold;
              color: #8071F6;
            }
            span {
              display: inline-flex;
              flex-direction: row;
              align-items: center;
              margin-bottom: 1.06rem;
              word-break: keep-all;
              i {
                display: inline-block;
                width: 0.31rem;
                height: 0.31rem;
                background: #cccccc;
                border-radius: 50%;
                margin-right: 0.25rem;
              }
            }
          }
        }
      }
      .share-test-oppor-form {
        margin-left: 5.38rem;
        .test-oppor-input {
          width: 17.81rem;
          height: 2.38rem;
          background: #fff;
          border: 1px solid #C2C7E2;
          border-radius: 0.2rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 0.88rem;
          // overflow: hidden;
          .label {
            font-size: 0.88rem;
            color: #888888;
            margin-right: 0.4rem;
            margin-left: 0.56rem;
            word-break: keep-all;
          }
          input {
            border: none;
            outline: medium;
            font-size: 1rem;
            color: #333;
          }
        }
      }
      .student-swiper {
        margin-left: 3.5rem;
        width: 14.5rem;
        .left-flag {
          width: 1.13rem;
          height: 1.13rem;
        }
        .right-flag {
          width: 1.13rem;
          height: 1.13rem;
        }
        .swiper {
          width: 9.25rem;
          height: 9.25rem;
          border-radius: 0.3rem;
        }
      }
    }
    .second-title {
      width: 100%;
      height: auto;
      text-align: center;
      color: #333;
      font-size: 1.88rem;
      line-height: 2.63rem;
      margin-top: 7rem;
      // margin-left: 4rem;
    }
    .question-container {
      margin-top: 2.5rem;
      margin-left: 16.56rem;
      .question-nav {
        margin-left: 1.71rem;
        width: 18rem;
        height: 24.75rem;
        box-shadow: -0.3rem 0rem 2rem 3px rgba(180, 180, 180, 0.3);
        .nav-top {
          width: 18rem;
          height: 10.81rem;
          // margin-left: 1.88rem;
        }
        .nav-bottom {
          margin-left: 0.65rem;
          margin-top: 0.88rem;
          span {
            color: #555555;
            font-size: 0.88rem;
            line-height: 1.25rem;
            display: inline-block;
            max-width: 16.5rem;
            margin-bottom: 0.75rem;
          }
        }
      }
    }
    .question-btn {
      width: 100%;
      height: auto;
      margin-top: 2.5rem;
      // margin-left: 4rem;
    }
  }
  .dream {
    margin-top: 7.88rem;
    background: rgba(240, 242, 248, 0.5);
    .title {
      margin-top: 3.69rem;
      margin-bottom: 3.19rem;
    }
    .power-container {
      margin-top: 2.5rem;
      .powier-nav-top {
        margin-top: 3.44rem;
      }
      .power-nav-bottom {
        margin-top: 4.28rem;
        margin-bottom: 5.31rem;
        .power-left {
          width: 2.75rem;
          height: 2.75rem;
          cursor: pointer;
        }
        .power-swiper {
          width: 60rem;
          height: 21.88rem;
          margin: 0 4.31rem;
          border-radius: 1rem;
          .power-swiper-item {
            width: 19rem;
            height: 21.75rem;
          }
        }
        .power-right {
          width: 2.75rem;
          height: 2.75rem;
          cursor: pointer;
        }
      }
    }
  }
  .team-advantage {
    .title {
      margin-top: 5.81rem;
      width: 18rem;
      height: 5.19rem;
    }
    .team-advantage-container {
      margin-top: 3.94rem;
      .tem-advantage-img {
        width: 14.63rem;
        height: 15.19rem;
        margin-right: 0.5rem;
      }
    }
  }
  .map-title {
    padding-top: 4.94rem;
    width: 100%;
    height: 5.13rem;
    background: rgb(244,244,244);
    // border: 0.1rem solid red;
    display: flex;
    justify-content: center;
  }
  .programme {
    .programme-title {
      margin-top: 4.5rem;
      .line {
        width: 48.06rem;
        height: 0.051rem;
        background: #d6d6d6;
      }
      .title {
        width: calc(20rem + 1.81rem + 1.94rem);
        font-size: 2rem;
        color: #8071F6;
        line-height: 2.81rem;
        background: white;
        text-align: center;
      }
    }
    .programme-container {
      margin-top: 2.88rem;
      margin-bottom: 5.75rem;
      .programm-input {
        width: 15.13rem;
        height: 4.25rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        border: 1px solid #C2C7E2;
        border-radius: 0.5rem;
        margin-right: 0.63rem;
        background: white;
        // overflow: hidden;
        .label {
          color: #666;
          letter-spacing: 0.06rem;
          line-height: 1.38rem;
          margin-left: 0.86rem;
          margin-right: 0.2rem;
          word-break: keep-all;
        }
        input {
          width: 9rem;
          border: none;
          outline: medium;
          font-size: 1.1rem;
          color: #333;
        }
      }
    }
  }
  .wwyt-title {
    width: 100%;
    height: auto;
    margin-top: 7.88rem;
  }
  .chose {
    width: 100%;
    height: 60.31rem;
    background: #2B364F;
    .chose-title {
      color: #fff;
      font-size: 2.38rem;
      line-height: 3.31rem;
      text-align: center;
      margin-top: 5.88rem;
    }
    .chose-container {
      margin-top: 5.13rem;
      .chose-container-left {
        .title {
          color: #ffffff !important;
          margin-bottom: 7.06rem;
        }
        .row {
          color: #cfcfcf;
          font-size: 1.88rem;
          line-height: 2.63rem;
          margin-bottom: 5.75rem;
          cursor: pointer;
          transition: all 0.1s;
          user-select: none;
        }
      }
      .chose-container-right {
        margin-left: -4.57rem;
        margin-top: 1.88rem;
        .right-img-box {
          width: 65.13rem;
          height: 37.5rem;
          transition: all 0.2s;
          .nav {
            display: flex;
            flex-direction: column;
            align-items: center;
            .title {
              display: block;
              font-size: 1.25rem;
              color: #fff;
              bottom: 1.56rem;
            }
            span {
              user-select: none;
              position: absolute;
              z-index: 1;
            }
            .text-content1 {
              display: block;
              width: 15.94rem;
              height: 3.75rem;
              font-size: 0.88rem;
              color: #fff;
              text-align: center;
              bottom: 3.44rem;
              opacity: 0;
            }
            .text-content2 {
              display: block;
              width: 29.38rem;
              height: 2.5rem;
              font-size: 0.88rem;
              color: #fff;
              text-align: center;
              bottom: 3.44rem;
              opacity: 0;
            }
            .text-content3 {
              display: block;
              font-size: 0.88rem;
              color: #fff;
              text-align: center;
              bottom: 5.31rem;
              opacity: 0;
            }
          }
          .nav-2 {
            .nav {
              position: relative;
              display: flex;
              flex-direction: column;
              align-items: center;
              .title {
                display: block;
                font-size: 1.25rem;
                color: #fff;
                bottom: 2.44rem;
              }
              .text-content21 {
                bottom: 2.7rem;
                right: 1.38rem;
                text-align: left;
                color: #fff;
                width: 15.44rem;
                height: 5rem;
                opacity: 0;
              }
              .text-content22 {
                bottom: 2.7rem;
                right: 5.38rem;
                text-align: left;
                color: #fff;
                width: 15.44rem;
                height: 5rem;
                opacity: 0;
              }
              .text-content23 {
                bottom: 4.38rem;
                right: 0.4rem;
                text-align: left;
                color: #fff;
                width: 16.44rem;
                height: 5rem;
                opacity: 0;
              }
              .text-content24 {
                bottom: 5rem;
                right: 15.13rem;
                text-align: left;
                color: #fff;
                width: 15.44rem;
                height: 5rem;
                opacity: 0;
              }
            }
          }
        }
      }
    }
  }
  .suspension {
    position: fixed;
    bottom: 20%;
    right: 0.4rem;
    z-index: 999;
    width: 4.63rem;
    height: 25.69rem;
    .navigation {
      width: 4.63rem;
      height: 10rem;
      padding-bottom: 0.6rem;
      padding-top: 2.6rem;
      background: rgba(3, 3, 3, 0.6);
      border-radius: 0.6rem;
      position: relative;
      transition: all 0.5s;
      #icon {
        position: absolute;
        left: 0;
        top: 1.15rem;
        right: 0;
        margin: 0 auto;
        width: 1.63rem;
        height: 0.88rem;
        background-image: url("https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/xuanfu/路径 3.png");
        background-size: contain;
        background-repeat: no-repeat;
        cursor: pointer;
      }
      span {
        color: #fff;
        font-size: 0.88rem;
        height: 1.94rem;
        line-height: 1.94rem;
        cursor: pointer;
        user-select: none;
        word-break: keep-all;
        display: block;
        width: 100%;
        text-align: center;
        border-radius: 0.3rem;
      }
      span:hover {
        color: #A6B6F8;
        // background: #8071F6;
        font-weight: bold;
      }
      .span-select {
        color: #A6B6F8;
        // background: #8071F6;
        font-weight: bold;
      }
    }
    .toTop {
      width: 4.63rem;
      height: 4.63rem;
      background: rgba(3, 3, 3, 0.6);
      border-radius: 0.6rem;
      margin-top: 0.5rem;
      cursor: pointer;
      user-select: none;
      .icon {
        width: 1.63rem;
        height: 1.75rem;
        background-image: url("https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/xuanfu/回到顶部.png");
        background-size: contain;
        background-repeat: no-repeat;
        margin-top: 1rem;
      }
      span {
        font-size: 0.75rem;
        color: #fff;
        margin-top: 0.19rem;
        word-break: keep-all;
      }
    }
    .toTop:hover {
      span {
        font-weight: bold;
      }
    }
    .service {
      width: 4.63rem;
      height: 4.63rem;
      background: #8071F6;
      border-radius: 0.6rem;
      margin-top: 1.25rem;
      user-select: none;
      .icon {
        width: 1.88rem;
        height: 1.88rem;
        background-image: url("https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/xuanfu/咨询 .png");
        background-size: contain;
        background-repeat: no-repeat;
        margin-top: 0.94rem;
      }
      span {
        font-size: 0.75rem;
        color: #fff;
        margin-top: 0.13rem;
        word-break: keep-all;
      }
    }
  }
}
</style>
