<template>
    <div id="wwyt" class="position-rel">
        <div class="center position-abs">
            <my-image src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/power-imgs/wwyt/bg.png"/>
        </div>
        <div class="cric-pane position-abs flex-colum flex-center" style="top: 3.19rem; left: 19.63rem">
            <div class="cric flex-colum flex-align-center" :style="`background:${selectedIndex == 1 ? '#8071F6' : '#A6B6F8'}`" @mouseenter="selectedIndex = 1">
                <div class="cric-logo">
                    <my-image width="2.56rem" height="2.94rem" src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/power-imgs/wwyt/xwgw.png"/>
                </div>
                <span>学术顾问</span>
            </div>
        </div>
        <div class="cric-pane position-abs flex-colum flex-center" style="top: 12.69rem; left: 32.25rem">
            <div class="cric flex-colum flex-align-center" :style="`background: ${selectedIndex == 2 ? '#8071F6' : '#A6B6F8'}`" @mouseenter="selectedIndex = 2">
                <div class="cric-logo">
                    <my-image width="2.63rem" height="2.75rem" src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/power-imgs/wwyt/wsds.png"/>
                </div>
                <span>文书导师</span>
            </div>
        </div>
        <div class="cric-pane position-abs flex-colum flex-center" style="top: 26.06rem; left: 27.63rem">
            <div class="cric flex-colum flex-align-center" :style="`background: ${selectedIndex == 3 ? '#8071F6' : '#A6B6F8'}`" @mouseenter="selectedIndex = 3">
                <div class="cric-logo">
                    <my-image width="2.56rem" height="2.81rem" src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/power-imgs/wwyt/xsgh.png"/>
                </div>
                <span>学术规划师</span>
            </div>
        </div>
        <div class="cric-pane position-abs flex-colum flex-center" style="top: 26.06rem; left: 11.63rem">
            <div class="cric flex-colum flex-align-center" :style="`background: ${selectedIndex == 4 ? '#8071F6' : '#A6B6F8'}`" @mouseenter="selectedIndex = 4">
                <div class="cric-logo">
                    <my-image width="3rem" height="3.06rem" src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/power-imgs/wwyt/wjrsds.png"/>
                </div>
                <span>外籍润色导师</span>
            </div>
        </div>
        <div class="cric-pane position-abs flex-colum flex-center" style="top: 13.69rem; left: 6.5rem">
            <div class="cric flex-colum flex-align-center" :style="`background: ${selectedIndex == 5 ? '#8071F6' : '#A6B6F8'}`" @mouseenter="selectedIndex = 5">
                <div class="cric-logo">
                    <my-image width="3rem" height="2.81rem" src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/power-imgs/wwyt/tutor.png"/>
                </div>
                <span>学术Tutor</span>
            </div>
        </div>
        <div :class="`text-pane position-abs ${selectedIndex == 1 ? 'checked' : ''}`" style="left: 9.7rem; top: -4.6rem;width: 32.56rem;height: 8.44rem;">
            <span class="title">专业学术咨询顾问： </span>
            <span>团队成员大多有海外学习背景，熟知海外学习生活，多年申请经验，主要负责根据学生全程签约流程、根据学生申请规划全程跟进其进度，负责服务前、中、后期解答学生申博疑问。</span>
        </div>
        
        <div :class="`text-pane position-abs ${selectedIndex == 2 ? 'checked' : ''}`" style="top: 14.7rem; right: -11.6rem;width: 18.44rem; height:6.25rem">
            <span class="title">资深后端文书导师： </span>
            <span> 负责学生基础文书（CV、RL、PS）的编辑与修改，套磁跟进，网申递交，面试辅导，及其他网申后跟进工作。</span>
        </div>

        <div :class="`text-pane position-abs ${selectedIndex == 3 ? 'checked' : ''}`" style="bottom: -0.25rem; right: 5rem;width: 14rem; height:3.75rem">
            <span class="title">学术产出质量把控专家：</span>
            <span style="width: 15rem">全程监控学术产出质量和文书质量。</span>
        </div>
        
        <div :class="`text-pane position-abs ${selectedIndex == 4 ? 'checked' : ''}`" style="bottom: -1.5rem; left: 5rem;width: 18.31rem; height:5rem">
            <span class="title">外籍润色：</span>
            <span> 曼博士外籍润色导师，均来自原生英语国家，负责为学生申请材料提供本土化语言润色服务。</span>
        </div>
        
        <div :class="`text-pane position-abs ${selectedIndex == 5 ? 'checked' : ''}`" style="top: 14.7rem; left: -12.5rem;width: 18.31rem; height:5rem">
            <span class="title">同领域名校导师：</span>
            <span>协助学生寻找研究方向，制定研究课题，辅导并协助学生完成符合申请要求的研究计划，为学生定制导师、项目和院校清单。</span>
        </div>
            <div style="font-size: 0.88rem;color:#A6B6F8;margin-top: 0.14rem;user-select:none;top: 20.5rem; left: -7rem;cursor: pointer;" class="cur-pointer; position-abs"><a href="http://www.manbosi.com/tutor/">博士生导师团队>></a></div>
    </div>
</template>

<script>
import MyImage from '@components/MyImage';
export default {
    data(){
        return{
            selectedIndex: 1
        }
    },
    components:{
        MyImage,
    }
}
</script>

<style lang="scss" scoped>
    a{
        color: inherit;
        text-decoration: none;
    }
    #wwyt{
        width: 52.81rem;
        height: 42.5rem;
        
        .center{
            width: 27.44rem;
            height: 27.44rem;
            top: 8.13rem;
            left: 11.81rem;
        }
        .cric-pane{
            width: 12.56rem;
            height: 12.5rem;
            .cric{
                transition: all .5s;
                cursor: pointer;
                width: 11.5rem;
                height: 11.5rem;
                border-radius: 50%;
                box-shadow: 0rem 0rem 0.7rem 0rem #aaaaaa;
                .cric-logo{
                    margin-top: 2.25rem;
                }
                span{
                    font-weight: Medium;
                    font-size: 1.38rem;
                    color: #fff;
                    margin-top: 0.75rem;
                    user-select: none;
                }
            }
        }
        .checked{
            font-family: PingFangSC-Semibold, sans-serif;
            font-weight: bold !important;
            // font-size: 1.13rem !important;
            transform: scale(1.14);
            color: #333 !important;
            div{
                font-size: 0.88rem;
                color: #666;
                text-align: center;
                font-weight: normal !important;
                transform: scale(0.86);
            }
        }
        .text-pane{
            transition: all .3s;
            font-size: 0.88rem;
            color: #666;
            text-align: center;
            .title{
                margin-bottom: 0.75rem;
            }
            span{
                display: block;
            }
            div{
                font-size: 0.88rem;
            }
        }
    }
</style>