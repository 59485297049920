<template>
    <div id="county-dot" class="position-rel" @mouseenter="checkDot">
        <div class="outer-layer flex-colum flex-center" :style="`background:${selected ? '#71BF88' : '#8071F6'}`">
            <div class="inner-layer flex-colum flex-center">
                <div class="icon flex-colum flex-center">
                    <my-image :src="`${countyIconPath[county]}`"/>
                </div>
            </div>

        </div>
        <div class="bottom-dot position-abs"  :style="`background:${selected ? '#71BF88' : '#8071F6'}`"></div>
        <span class="position-abs" style="width:5rem;margin:0 auto;text-align:center;left: 0.2rem">{{cNames[county]}}</span>
    </div>
</template>

<script>
import MyImage from '@components/MyImage';
export default {    
    props: ['county','selected'],
    data(){
        return{
            countyIconPath: {
                yg:  'https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/county-icon/英国.png',
                oz:  'https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/county-icon/欧洲.png',
                am:  'https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/county-icon/澳门.png',
                bm:  'https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/county-icon/北美.png',
                dyz:  'https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/county-icon/大洋洲.png',
                dny:  'https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/county-icon/东南亚.png'
            },
            cNames: {
                yg:'英国',
                oz:'欧洲',
                am:'中国香港中国澳门',
                bm:'北美',
                dyz:'大洋洲',
                dny:'东南亚',
            }
        }
    },
    components: {
        MyImage,
    },
    methods: {
        checkDot(){
            this.$emit('checked',{data: this.county});
        }
    }
    
}
</script>

<style lang="scss" scoped>
    #county-dot{
        display: inline-block;
        z-index: 1;
        // transform: scale(0.8);
        .outer-layer{
            width: 5.38rem;
            height: 5.38rem;
            border-radius: 50% 50% 0% 50%;
            // background: #FBBD00;
            transform: rotate(45deg);
            transition: all .3s;
            cursor: pointer;
            .inner-layer{
                width: 3.68rem;
                height: 3.68rem;
                border-radius: 50%;
                // background: #6A328B;
                background:rgb(43,54,79);
                .icon{
                    transform: rotate(-45deg);
                    width: 2.65rem;
                    height: 2.65rem;
                }
            }
        }
        .bottom-dot{
            width: 1.8rem;
            height: 0.6rem;
            border-radius: 50%;
            // background: #FBBD00;
            left: 1.79rem;
            transition: all .3s;
            top: 7rem
        }
        span{
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 1.13rem;
            color: #333;
            left: 1.5rem;
            top: 7.8rem
        }
    }
</style>