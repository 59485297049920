<template>
  <div id="map" class="flex-row flex-jusity-center">
      <div id="map-action"></div>
      <div class="right flex-colum">
          <div class="row flex-row flex-center">
              <span class="span1">输送学员数量</span>
              <span class="span2">2000+</span>
          </div>
          <!-- <div class="row flex-row flex-center">
              <span class="span1">申博通过率</span>
              <span class="span2">98%</span>
          </div>
          <div class="row flex-row flex-center">
              <span class="span1">专业覆盖率</span>
              <span class="span2">96%</span>
          </div> -->
          <div class="row flex-row flex-center">
              <span class="span1">全球输送学员国家/地区</span>
              <span class="span2">超32个</span>
          </div>
          <div class="row flex-row flex-center">
              <span class="span1">TOP200的offer占比</span>
              <span class="span2">67%</span>
          </div>
      </div>
  </div>
</template>

<script>
const echart = require("echarts");
require("echarts/map/js/world");
export default {
  data() {
    return {
        geoCoordMap: {
            '中国-上海': [121.482498,31.233585],
            '香港': [116.481337,23.257813],
            '澳门': [111.514067,21.61594],
            '加拿大': [-106.437014,56.325136],
            '美国': [-101.506538,40.14741],
            '马来西亚': [99.905372,7.095935],
            '新加坡': [104.044764,0.983338],
            '澳大利亚': [133.316559,-25.600166],
            '新西兰': [169.998469,-44.269857],
            '芬兰': [25.287632,68.378314],
            '瑞典': [16.751286,63.597046],
            '挪威': [7.773405,60.923405],
            '英国': [-2.087546,54.739353],
            '丹麦': [9.392367,56.325136],
            '荷兰': [5.933675,53.102505],
            '比利时': [5.234578,50.040075],
            '德国': [10.569794,51.279587],
            '法国': [1.223967,47.58631],
            '瑞士': [8.141351,47.086044],
            '西班牙': [-3.191384,39.523329],
            '意大利': [12.409524,43.181603],
        },
        pathData: [
            [{name: '中国-上海'},{label: '', name: '中国-上海', value: 1}],
            [{name: '中国-上海'},{label: '', name: '香港', value: 1}],
            [{name: '中国-上海'},{label: '', name: '澳门', value: 1}],
            [{name: '中国-上海'},{label: '', name: '马来西亚', value: 1}],
            [{name: '中国-上海'},{label: '', name: '新加坡', value: 1}],
            [{name: '中国-上海'},{label: '', name: '澳大利亚', value: 1}],
            [{name: '中国-上海'},{label: '', name: '新西兰', value: 1}],
            [{name: '中国-上海'},{label: '', name: '芬兰', value: 1}],
            [{name: '中国-上海'},{label: '', name: '瑞典', value: 1}],
            [{name: '中国-上海'},{label: '', name: '挪威', value: 1}],
            [{name: '中国-上海'},{label: '', name: '英国', value: 1}],
            [{name: '中国-上海'},{label: '', name: '丹麦', value: 1}],
            [{name: '中国-上海'},{label: '', name: '荷兰', value: 1}],
            [{name: '中国-上海'},{label: '', name: '比利时', value: 1}],
            [{name: '中国-上海'},{label: '', name: '德国', value: 1}],
            [{name: '中国-上海'},{label: '', name: '法国', value: 1}],
            [{name: '中国-上海'},{label: '', name: '瑞士', value: 1}],
            [{name: '中国-上海'},{label: '', name: '西班牙', value: 1}],
            [{name: '中国-上海'},{label: '', name: '意大利', value: 1}],
            [{name: '中国-上海'},{label: '', name: '加拿大', value: 1}],
            [{name: '中国-上海'},{label: '', name: '美国', value: 1}],
        ],
        path: 'path://M1705.06,1318.313v-89.254l-319.9-221.799l0.073-208.063c0.521-84.662-26.629-121.796-63.961-121.491c-37.332-0.305-64.482,36.829-63.961,121.491l0.073,208.063l-319.9,221.799v89.254l330.343-157.288l12.238,241.308l-134.449,92.931l0.531,42.034l175.125-42.917l175.125,42.917l0.531-42.034l-134.449-92.931l12.238-241.308L1705.06,1318.313z',
        color: ['#FF5879'],
        series: [],
        option: {}
    };
  },
  mounted() {
      this.initSeries();
      this.initOption();
      const myChart = echart.init(document.getElementById('map-action'));
      myChart.setOption(this.option);
  },
  methods:{
    convertData(data) {
        var res = [];
        for (var i = 0; i < data.length; i++) {
            var dataItem = data[i];
            var fromCoord = this.geoCoordMap[dataItem[0].name];
            var toCoord = this.geoCoordMap[dataItem[1].name];
            if (fromCoord && toCoord) {
                res.push({
                    fromName: '中国',
                    toName: dataItem[1].name,
                    coords: [fromCoord, toCoord]
                });
            }
        }
        return res;
    },
    initSeries(){
        var _slef = this;
        this.series.push({
            name: '',
            type: 'lines',
            zlevel: 2,
            progressiveThreshold: 30000,
            effect: {
                show: true,
                period: 6,
                trailLength: 0.3,
                color: '#CC9B42',
                symbolSize: 4,
            },
            lineStyle: {
                normal: {
                    width: 0,
                    curveness: 0.2
                }
            },
            data: this.convertData(this.pathData)
        },{
            name: '',
            type: 'lines',
            zlevel: 1,
            symbol: ['none', 'circle'],
            symbolSize: 3,
            effect: {
                show: true,
                period: 6,
                trailLength: 0,
                symbolSize: 2
            },
            lineStyle: {
                color: '#CC9B42',
                width: 1,
                opacity: 1,
                curveness: 0.2
            },
            data: this.convertData(this.pathData)
        },{
            name: '',
            type: 'effectScatter',
            coordinateSystem: 'geo',
            zlevel: 3,
            rippleEffect: {
                brushType: 'stroke'
            },
            label: {
                show: false,
                position: 'top',
                formatter: '{b}',
                fontSize: 10,
                color: '#333'
            },
            symbolSize: 7,
            itemStyle: {
                color: '#FF5879'
            },
            data: this.pathData.map(function (dataItem) {
                return {
                    name: dataItem[1].name,
                    // value: dataItem[1].name
                    value: _slef.geoCoordMap[dataItem[1].name].concat([dataItem[1].value]),
                    tooltip: dataItem[1].name
                };
            })
        });
    },
    initOption(){
        this.option = {
            backgroundColor: '#F4F4F4',
            tooltip: {
                trigger: 'item',
            },
            geo: {
                map: 'world',
                label: {
                    show: false
                },
                // center: [38.680864,8.929795],
                aspectScale: 0.75,
                roam: false,
                zoom: 1,
                itemStyle: {
                    areaColor: '#348CFD',
                    borderColor: '#348CFD'
                },

                emphasis: {
                    label: {
                        show: false
                    },
                    itemStyle: {
                        areaColor: '#348CFD'
                    }
                }
            },
            series: this.series
        };
    },
  }
};
</script>

<style lang="scss" scoped>
#map{
    background: #F4F4F4;
    #map-action {
    width: 50%;
    height: 40rem;
    margin-left: -5rem;
    }
    .right{
        margin-top: 10.56rem;
        .row{
            margin-bottom: 2.25rem;
            .span1{
                color: #333333;
                font-size: 1.1rem;
                margin-right: 2.06rem;
                min-width: 11.44rem;
                text-align: right;
            }
            .span2{
                font-weight: BoldMT;
                color: #005CD1;
                font-size: 2rem;
                min-width: 7.12rem;
                text-align: left;
            }
        }
    }
}
</style>
