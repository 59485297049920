<template>
    <div id="image">
        <div :class="`${round ? 'img-container position-rel round' : 'img-container position-rel'}`" :style="`width: ${width};height: ${height}`">
            <img v-if="lazyload && !staticFlag" :class="`${round ? 'round' : ''}`" v-lazy="src" :key="src" :draggable="drag" :alt="alt" :title="title"/>
            <img v-if="!lazyload && !staticFlag" :class="`${round ? 'round' : ''}`" :src="src" :key="src" :draggable="drag" :alt="alt" :title="title"/>
            <div class="divImg" v-if="staticFlag && !lazyload" :class="`${round ? 'round' : ''}`" :style="`background-image:url(${src})`" :key="'111'+src" :draggable="drag" :alt="alt" :title="title"></div>
            <!-- <div class="divImg" :style="`background-image: ${src}`" v-if="staticFlag && !lazyload" :class="`${round ? 'round' : ''}`" :key="'111'+src" :draggable="drag" :alt="alt" :title="title"></div> -->
            <slot></slot>
        </div>
    </div>
</template>

<script>
import { type } from 'os'
export default {
    props: {
        src: {
            type: String|Object|Function,
            require: true
        },
        width: {
            type: String,
            default: '100%'
        },
        height: {
            type: String,
            default: '100%'
        },
        drag: {
            type: Boolean,
            default: false
        },
        round: {
            type: Boolean,
            default: false
        },
        full: {
            type: Boolean,
            default: true
        },
        lazyload: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        },
        alt: {
            type: String,
            default: '正在加载...'
        },
        staticFlag: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss" scoped>
    #image{
        user-select:none;
        display: inline-block;
        overflow: hidden;
        .img-container{
            display: inline-block;
            transform: all .3s;
        }
    }
    .round{
        border-radius: 50%;
    }
    .divImg{
        width: 100%;
        height: 100%;
        background-size: cover;
    }
</style>
