<template>
    <div :id="'subtitle'+index" class="subtitle-container">
        <nav class="subtitle flex-row flex-align-center" :style="`color:${color}`">
            <my-image style="margin-right: 0.63rem" width="0.38rem" height="2rem" :drag="false" src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/subtitle-line.png"/>
            {{text}}
        </nav>
    </div>
</template>

<script>
import MyImage from '@components/MyImage';

import anime from 'animejs/lib/anime.es.js';
export default {
    props: {
        text: {
            type: String
        },
        index: {
            type: String
        },
        color: {
            type: String,
            default: '#333'
        }
    },
    components:{
        MyImage
    },
    mounted(){
        this.initComponents();
    },
    methods:{
        initComponents(){
            let that = this;
            let fun = function() {
                if(document.querySelector('#subtitle'+that.index).getBoundingClientRect().top <= 260){
                // console.log(that.index)
                    that.initAnime();
                    window.removeEventListener('scroll', fun);
                    return;
                }
            }
            this.$nextTick(()=>{
                window.addEventListener('scroll', fun);
            })
        },
        initAnime(){
            let that = this;
            let initAction = anime({
                targets: '#subtitle'+that.index,
                translateY: ['-3rem','0rem'],
                opacity: 1,
                duration: 400,
                // loop: true,
                // direction: 'alternate',
                easing: 'linear',
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    .subtitle-container{
        // transform: translateY(-3rem);
        opacity: 0;
        .subtitle{
            font-size: 2.5rem;
            font-family: PingFangSC-Bold, PingFang SC;
            font-weight: bold;
            line-height: 3.5rem;
            text-align: left;
        }
    }
</style>