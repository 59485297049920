<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  mounted(){
    let url = document.referrer;
    if(this.$route.query.pc){
      this.$cookies.set('xiaocms_pc', this.$route.query.pc, '1d');
    }else{
      this.$cookies.set('xiaocms_pc', '', '1d');
    }
    if(url.indexOf('\/')){
      this.$cookies.set('xiaocms_http_referer', url, '1d');
    }else{
      this.$cookies.set('xiaocms_http_referer', '', '1d');
    }
  },
  methods: {
    linktab() {
      let goUrl = this.isMobile();
      if (goUrl === 1) {
        //移动端地址
        location = "http://m.manbosi.com"
        // console.log("移动")
      } else {
        //PC地址
        // location = "http://www.manbosi.com"
        // console.log("PC地址")
      }
    },
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      let goUrl = flag ? 1 : 0;
      return goUrl;
    },
  },
  created() {
	  this.linktab();
  },
  destroyed(){
    this.$cookies.remove('xiaocms_pc')
    this.$cookies.remove('xiaocms_http_referer')
  },
  
}
</script>

<style lang="scss">
@media (max-width: 1920px) {
  body,html{
    font-size: 16px;
  }
}
@media (max-width: 1680px) {
  body,html{
    font-size: 14px;
  }
}
@media (max-width: 1366px) {
  body,html{
    font-size: 13px;
  }
}
@media (max-width: 1360px) {
  body,html{
    font-size: 13px;
  }
}
@media (max-width: 1280px) {
  body,html{
    font-size: 10px;
  }
}
@media (max-width: 1024px) {
  body,html{
    font-size: 9px;
  }
}
body,html{
  font-family: PingFangSC-Regular, sans-serif;
  color: #333;
  // font-size: 16px;
}
</style>
